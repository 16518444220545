import React from "react";
import Aside from "../Layouts/sidebar/aside";
import Navbar from "../Layouts/navbar";
import Footer from "../Layouts/footer";
import { Link } from "react-router-dom";


export default function DrawdownRevenue() {


  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">

          <Aside />

          <div className="layout-page">

            <Navbar />

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h3 className="mb-2 ps-3">Drawdown Revenue</h3>
                <div className="row">
                  <div className="col-lg-12">

                    <div className="card mb-4">
                      {/* <!-- Account --> */}

                      <div className="card-body">
                        <form id="formAccountSettings" >
                          <div className="row">
                            <div className="col-lg-9">
                              <div className="row">
                                <div className="mb-3 col-sm-12 col-md-6 col-lg-4">
                                  <label htmlFor="customerName" className="form-label">Client Name</label>
                                  <input className="form-control" type="text" id="customerName" name="customerName" />
                                </div>

                                <div className="mb-3 col-sm-12 col-md-6 col-lg-4">
                                  <label className="form-label" htmlFor="clientNumber">Client Code</label>
                                  <div className="input-group input-group-merge">
                                    <input type="text" id="clientNumber" name="clientNumber" className="form-control" placeholder="GBR 555 0111" />
                                  </div>
                                </div>

                                <div className="mb-3 col-sm-12 col-md-6 col-lg-4">
                                  <label htmlFor="dealNo" className="form-label">Deal No</label>
                                  <input type="text" className="form-control" id="deal No" name="dealNo" placeholder="SP1234587" />
                                </div>

                                <div className="mb-3 col-sm-12 col-md-6 col-lg-4">
                                  <label htmlFor="dealNo" className="form-label">Drawdown Date (From)</label>
                                  <input type="text" className="form-control" id="deal No" name="dealNo" placeholder="" />
                                </div>

                                <div className="mb-3 col-sm-12 col-md-6 col-lg-4">
                                  <label htmlFor="dealNo" className="form-label">Drawdown Date (To)</label>
                                  <input type="text" className="form-control" id="deal No" name="dealNo" placeholder="" />
                                </div>

                                <div className="mb-3 col-sm-12 col-md-6 col-lg-4">

                                  <label className="form-label" htmlFor="sellCurrency">Select sell Currency</label>
                                  <select id="sellCurrency" className="select2 form-select">
                                    <option value="">Select</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Bangladesh">Bangladesh</option>
                                    <option value="Belarus">Belarus</option>
                                    <option value="Brazil">Brazil</option>
                                  </select>

                                </div>

                                <div className="mb-3 col-sm-12 col-md-6 col-lg-4">
                                  <label htmlFor="buyCurrency" className="form-label">Select Buy Currency</label>
                                  <select id="buyCurrency" className="select2 form-select">
                                    <option value="">Select</option>
                                    <option value="usd">USD</option>
                                    <option value="euro">Euro</option>
                                    <option value="pound">Pound</option>
                                    <option value="bitcoin">Bitcoin</option>
                                  </select>
                                </div>

                                <div className="mb-3 col-sm-12 col-md-6 col-lg-4">
                                  <label htmlFor="buyCurrency" className="form-label">All Brands</label>
                                  <select id="buyCurrency" className="select2 form-select">
                                    <option value="">Select</option>
                                    <option value="usd">USD</option>
                                    <option value="euro">Euro</option>
                                    <option value="pound">Pound</option>
                                    <option value="bitcoin">Bitcoin</option>
                                  </select>
                                </div>

                                <div className="mb-3 col-sm-12 col-md-6 col-lg-4">
                                  <label htmlFor="buyCurrency" className="form-label">All</label>
                                  <select id="buyCurrency" className="select2 form-select">
                                    <option value="">Select</option>
                                    <option value="usd">USD</option>
                                    <option value="euro">Euro</option>
                                    <option value="pound">Pound</option>
                                    <option value="bitcoin">Bitcoin</option>
                                  </select>
                                </div>

                                <div className="mb-3 col-sm-12 col-md-6 col-lg-4">
                                  <label htmlFor="status" className="form-label">Select Deal Type</label>
                                  <input type="text" className="form-control" id="status" name="status" />
                                </div>

                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="mb-3 col-lg-12">
                                <label htmlFor="dealNo" className="form-label">Date (From)</label>
                                <input type="date" className="form-control" id="deal No" name="dealNo" placeholder="SP1234587" />
                              </div>

                              <div className="mb-3 col-lg-12">
                                <label htmlFor="dealNo" className="form-label">Date (To)</label>
                                <input type="date" className="form-control" id="deal No" name="dealNo" placeholder="SP1234587" />
                              </div>
                            </div>

                          </div>



                          <div className="row mt-2">
                            <div className="col-lg-12 d-flex align-items-center">
                              <button type="submit" className="btn btn-primary me-2">Save changes</button>
                              <button type="reset" className="btn btn-label-secondary">Cancel</button>
                            </div>
                          </div>

                        </form>
                      </div>

                      {/* <!-- /Account --> */}
                    </div>

                    <div className="card">

                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="card mb-4">
                      {/* <!-- Account --> */}

                      <div className="card-body">
                        <div className="card-datatable table-responsive ps-3">
                          <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">

                            <table className="datatables-customers hm-table table border-top dataTable no-footer dtr-column hm-table-color" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info">
                              <thead className="table-head-bg" >
                                <tr>
                                  <th className="control sorting_disabled dtr-hidden"  >No</th>
                                  <th className="sorting sorting_desc" >
                                    Brand
                                  </th>
                                  <th className="text-nowrap sorting"  >
                                    Deal Type
                                  </th>
                                  <th className="sorting" >
                                    type
                                  </th>
                                  <th className="sorting"  >
                                    Customer
                                  </th>
                                  <th className="sorting"  >
                                    Client Code
                                  </th>
                                  <th className="sorting" >
                                    Deal no
                                  </th>
                                  <th className="sorting"  >
                                    Order Date
                                  </th>
                                  <th className="sorting"  >
                                    Drawdown Reference
                                  </th>
                                  <th className="sorting"  >
                                    Drawdown Date
                                  </th>
                                  <th className="sorting"  >
                                    Date Completed
                                  </th>
                                  <th className="sorting"  >
                                    Date Revenue
                                  </th>
                                  <th className="sorting"  >
                                    Drawdown %
                                  </th>
                                  <th className="sorting"  >
                                    Settle Revenue
                                  </th>
                                  <th className="sorting"  >
                                    Bank Charges
                                  </th>
                                  <th className="sorting"  >
                                    Net Revenue
                                  </th>
                                  <th className="sorting"  >
                                    Account Manager
                                  </th>
                                  <th className="sorting"  >
                                    Business
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {/* first Row */}
                                <tr>
                                  <td className="control sorting_disabled dtr-hidden"  >1</td>
                                  <td className="sorting sorting_desc" >
                                    Equals
                                  </td>
                                  <td className="text-nowrap sorting"  >
                                    SPOT
                                  </td>
                                  <td className="sorting" >
                                    Wallet
                                  </td>
                                  <td className="sorting"  >
                                    Paul Cola
                                  </td>
                                  <td className="sorting"  >
                                    GBR0315651
                                  </td>
                                  <td className="sorting" >
                                    SP43543135
                                  </td>
                                  <td className="sorting" >
                                    13-12-2023
                                  </td>
                                  <td className="sorting"  >
                                    Transfer To Wallet
                                  </td>
                                  <td className="sorting"  >
                                    01-01-2024 10:50
                                  </td>
                                  <td className="sorting"  >
                                    01-01-2024
                                  </td>
                                  <td className="sorting"  >
                                    10.67 GBP
                                  </td>
                                  <td className="sorting"  >
                                    100%
                                  </td>
                                  <td className="sorting"  >
                                    10.67 GBP
                                  </td>
                                  <td className="sorting"  >
                                    0.00
                                  </td>
                                  <td className="sorting"  >
                                    10.67
                                  </td>
                                  <td className="sorting" >
                                    Benjamin Gringer
                                  </td>
                                  <td className="sorting" >
                                    George
                                  </td>
                                </tr>

                                {/* Second Row */}
                                <tr>
                                  <td className="control sorting_disabled dtr-hidden"  >2</td>
                                  <td className="sorting sorting_desc" >
                                    Equals
                                  </td>
                                  <td className="text-nowrap sorting"  >
                                    SPOT
                                  </td>
                                  <td className="sorting" >
                                    Wallet
                                  </td>
                                  <td className="sorting"  >
                                    Paul Cola
                                  </td>
                                  <td className="sorting"  >
                                    GBR0315651
                                  </td>
                                  <td className="sorting" >
                                    SP43543135
                                  </td>
                                  <td className="sorting" >
                                    13-12-2023
                                  </td>
                                  <td className="sorting"  >
                                    Transfer To Wallet
                                  </td>
                                  <td className="sorting"  >
                                    01-01-2024 10:50
                                  </td>
                                  <td className="sorting"  >
                                    01-01-2024
                                  </td>
                                  <td className="sorting"  >
                                    10.67 GBP
                                  </td>
                                  <td className="sorting"  >
                                    100%
                                  </td>
                                  <td className="sorting"  >
                                    10.67 GBP
                                  </td>
                                  <td className="sorting"  >
                                    0.00
                                  </td>
                                  <td className="sorting"  >
                                    10.67
                                  </td>
                                  <td className="sorting" >
                                    Benjamin Gringer
                                  </td>
                                  <td className="sorting" >
                                    George
                                  </td>
                                </tr>

                              </tbody>
                            </table>


                            <div className="row mx-2">

                              <div className="col-sm-12 col-lg-12">
                                <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                                  <ul className="pagination">

                                    <li className="paginate_button page-item previous disabled" id="DataTables_Table_0_previous">
                                      <Link data-dt-idx="previous" tabIndex={0} className="page-link">
                                        Previous
                                      </Link>
                                    </li>

                                    <li className="paginate_button page-item next disabled" id="DataTables_Table_0_next" >
                                      <Link data-dt-idx="next" tabIndex={0} className="page-link">
                                        Next
                                      </Link>
                                    </li>

                                  </ul>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <Footer />

            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>
    </>
  )
}