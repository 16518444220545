import React, { useState } from 'react';
import Aside from '../Layouts/sidebar/aside';
import SidebarNavbar from '../Layouts/navbar';
import user from "../../../assets/img/avatars/1.png";


const Profile = () => {

  const [menu, setMenu] = useState("accounts");
  const [passwordVisible, setPasswordVisible] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [uploadedImage, setUploadedImage] = useState(null); // State to store uploaded image

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    // Update the state with the uploaded image
    setUploadedImage(URL.createObjectURL(file));
  };


  const AccountCom = () => {
    document.getElementById("hm_security").style.display = "none";
    document.getElementById("hm_account").style.display = "block";
    setMenu("accounts");
  }

  const SecurityCom = () => {
    document.getElementById("hm_account").style.display = "none";
    document.getElementById("hm_security").style.display = "block";
    setMenu("Security");
  }
  const togglePasswordVisibility = (field) => {
    setPasswordVisible((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Aside />
          <div className="layout-page">
            <SidebarNavbar />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="py-3 mb-4">
                  <span className="text-muted fw-light">Account Settings /</span> Account
                </h4>
                <div className="row fv-plugins-icon-container">
                  <div className="col-md-12">
                    <ul className="nav nav-pills flex-column flex-md-row mb-4">
                      <li className="nav-item">
                        <button onClick={AccountCom} className={`nav-link ${menu === "accounts" ? "active" : ""}`}><i className="ti-xs ti ti-users me-1"></i> Account</button>
                      </li>
                      <li className="nav-item">
                        <button onClick={SecurityCom} className={`nav-link ${menu === "Security" ? "active" : ""}`}><i className="ti-xs ti ti-lock me-1"></i> Security</button>
                      </li>
                    </ul>
                    <div id='hm_account'>
                      <div className="card mb-4">
                        <h5 className="card-header">Profile Details</h5>
                        {/* <!-- Account --> */}
                        <div className="card-body">
                          <div className="d-flex align-items-start align-items-sm-center gap-4">
                            <img src={uploadedImage || user} alt="user-avatar" className="d-block w-px-100 h-px-100 rounded" id="uploadedAvatar" />
                            <div className="button-wrapper">
                              <label htmlFor="upload" className="btn btn-primary me-2 mb-3 waves-effect waves-light" tabIndex="0">
                                <span className="d-none d-sm-block">Upload new photo</span>
                                <i className="ti ti-upload d-block d-sm-none"></i>
                                <input
                                  type="file"
                                  id="upload"
                                  className="account-file-input"
                                  style={{ display: "none" }}
                                  hidden=""
                                  accept="image/png, image/jpeg"
                                  onChange={handleImageUpload} 
                                />
                              </label>
                              <button type="button" className="btn btn-label-secondary account-image-reset mb-3 waves-effect">
                                <i className="ti ti-refresh-dot d-block d-sm-none"></i>
                                <span className="d-none d-sm-block">Reset</span>
                              </button>

                              <div className="text-muted">Allowed JPG, GIF or PNG. Max size of 800K</div>
                            </div>
                          </div>
                        </div>
                        <hr className="my-0" />
                        <div className="card-body">
                          <form id="formAccountSettings" className="fv-plugins-bootstrap5 fv-plugins-framework" >
                            <div className="row">
                              <div className="mb-3 col-md-6 fv-plugins-icon-container">
                                <label htmlFor="firstName" className="form-label">First Name</label>
                                <input className="form-control" type="text" id="firstName" name="firstName" autofocus="" />
                                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>
                              <div className="mb-3 col-md-6 fv-plugins-icon-container">
                                <label htmlFor="lastName" className="form-label">Last Name</label>
                                <input className="form-control" type="text" name="lastName" id="lastName" />
                                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>
                              <div className="mb-3 col-md-6">
                                <label htmlFor="email" className="form-label">E-mail</label>
                                <input className="form-control" type="text" id="email" name="email" placeholder="john.doe@example.com" />
                              </div>
                              <div className="mb-3 col-md-6">
                                <label htmlFor="organization" className="form-label">Organization</label>
                                <input type="text" className="form-control" id="organization" name="organization"  />
                              </div>
                              <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="phoneNumber">Phone Number</label>
                                <div className="input-group input-group-merge">
                                  <span className="input-group-text">US (+1)</span>
                                  <input type="text" id="phoneNumber" name="phoneNumber" className="form-control" placeholder="202 555 0111" />
                                </div>
                              </div>
                              <div className="mb-3 col-md-6">
                                <label htmlFor="address" className="form-label">Address</label>
                                <input type="text" className="form-control" id="address" name="address" placeholder="Address" />
                              </div>
                              <div className="mb-3 col-md-6">
                                <label htmlFor="state" className="form-label">State</label>
                                <input className="form-control" type="text" id="state" name="state" placeholder="California" />
                              </div>
                              <div className="mb-3 col-md-6">
                                <label htmlFor="zipCode" className="form-label">Zip Code</label>
                                <input type="text" className="form-control" id="zipCode" name="zipCode" placeholder="231465" maxlength="6" />
                              </div>
                              <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="country">Country</label>
                                <div className="position-relative"><select id="country" className="select2 form-select select2-hidden-accessible" data-select2-id="country" tabindex="-1" aria-hidden="true">
                                  <option value="" data-select2-id="2">Select</option>
                                  <option value="Australia">Australia</option>
                                  <option value="Bangladesh">Bangladesh</option>
                                  <option value="Belarus">Belarus</option>
                                  <option value="Brazil">Brazil</option>
                                  <option value="Canada">Canada</option>
                                  <option value="China">China</option>
                                  <option value="France">France</option>
                                  <option value="Germany">Germany</option>
                                  <option value="India">India</option>
                                  <option value="Indonesia">Indonesia</option>
                                  <option value="Israel">Israel</option>
                                  <option value="Italy">Italy</option>
                                  <option value="Japan">Japan</option>
                                  <option value="Korea">Korea, Republic of</option>
                                  <option value="Mexico">Mexico</option>
                                  <option value="Philippines">Philippines</option>
                                  <option value="Russia">Russian Federation</option>
                                  <option value="South Africa">South Africa</option>
                                  <option value="Thailand">Thailand</option>
                                  <option value="Turkey">Turkey</option>
                                  <option value="Ukraine">Ukraine</option>
                                  <option value="United Arab Emirates">United Arab Emirates</option>
                                  <option value="United Kingdom">United Kingdom</option>
                                  <option value="United States">United States</option>
                                </select>
                                  <span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="1" style={{ width: "660px;" }}><span className="selection"><span className="select2-selection select2-selection--single" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-country-container"><span className="select2-selection__rendered" id="select2-country-container" role="textbox" aria-readonly="true" title="Select">Select</span><span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span className="dropdown-wrapper" aria-hidden="true"></span></span></div>
                              </div>
                              <div className="mb-3 col-md-6">
                                <label htmlFor="language" className="form-label">Language</label>
                                <div className="position-relative"><select id="language" className="select2 form-select select2-hidden-accessible" data-select2-id="language" tabindex="-1" aria-hidden="true">
                                  <option value="" data-select2-id="4">Select Language</option>
                                  <option value="en">English</option>
                                  <option value="fr">French</option>
                                  <option value="de">German</option>
                                  <option value="pt">Portuguese</option>
                                </select>
                                  <span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="3" style={{ width: "660px;" }}><span className="selection"><span className="select2-selection select2-selection--single" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-language-container"><span className="select2-selection__rendered" id="select2-language-container" role="textbox" aria-readonly="true" title="Select Language">Select Language</span><span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span className="dropdown-wrapper" aria-hidden="true"></span></span></div>
                              </div>
                              <div className="mb-3 col-md-6">
                                <label htmlFor="timeZones" className="form-label">Timezone</label>
                                <div className="position-relative"><select id="timeZones" className="select2 form-select select2-hidden-accessible" data-select2-id="timeZones" tabindex="-1" aria-hidden="true">
                                  <option value="" data-select2-id="6">Select Timezone</option>
                                  <option value="-12">(GMT-12:00) International Date Line West</option>
                                  <option value="-11">(GMT-11:00) Midway Island, Samoa</option>
                                  <option value="-10">(GMT-10:00) Hawaii</option>
                                  <option value="-9">(GMT-09:00) Alaska</option>
                                  <option value="-8">(GMT-08:00) Pacific Time (US &amp; Canada)</option>
                                  <option value="-8">(GMT-08:00) Tijuana, Baja California</option>
                                  <option value="-7">(GMT-07:00) Arizona</option>
                                </select>
                                  <span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="5" style={{ width: "660px;" }}><span className="selection"><span className="select2-selection select2-selection--single" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-timeZones-container"><span className="select2-selection__rendered" id="select2-timeZones-container" role="textbox" aria-readonly="true" title="Select Timezone">Select Timezone</span><span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span className="dropdown-wrapper" aria-hidden="true"></span></span></div>
                              </div>
                              <div className="mb-3 col-md-6">
                                <label htmlFor="currency" className="form-label">Currency</label>
                                <div className="position-relative"><select id="currency" className="select2 form-select select2-hidden-accessible" data-select2-id="currency" tabindex="-1" aria-hidden="true">
                                  <option value="" data-select2-id="8">Select Currency</option>
                                  <option value="usd">USD</option>
                                  <option value="euro">Euro</option>
                                  <option value="pound">Pound</option>
                                  <option value="bitcoin">Bitcoin</option>
                                </select><span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="7" style={{ width: "660px;" }}><span className="selection"><span className="select2-selection select2-selection--single" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-currency-container"><span className="select2-selection__rendered" id="select2-currency-container" role="textbox" aria-readonly="true" title="Select Currency">Select Currency</span><span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span className="dropdown-wrapper" aria-hidden="true"></span></span></div>
                              </div>
                            </div>
                            <div className="mt-2">
                              <button type="submit" className="btn btn-primary me-2 waves-effect waves-light">Save changes</button>
                              <button type="reset" className="btn btn-label-secondary waves-effect">Cancel</button>
                            </div>
                            <input type="hidden" /></form>
                        </div>
                        {/* <!-- /Account --> */}
                      </div>
                      <div className="card">
                        <h5 className="card-header">Delete Account</h5>
                        <div className="card-body">
                          <div className="mb-3 col-12">
                            <div className="alert alert-warning">
                              <h5 className="alert-heading mb-1">Are you sure you want to delete your account?</h5>
                              <p className="mb-0">Once you delete your account, there is no going back. Please be certain.</p>
                            </div>
                          </div>
                          <form id="formAccountDeactivation" className="fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
                            <div className="form-check mb-4">
                              <input className="form-check-input" type="checkbox" name="accountActivation" id="accountActivation" />
                              <label className="form-check-label" htmlFor="accountActivation">I confirm my account deactivation</label>
                              <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>
                            <button type="submit" className="btn btn-danger deactivate-account waves-effect waves-light">Deactivate Account</button>
                            <input type="hidden" /></form>
                        </div>
                      </div>
                    </div>
                    <div id="hm_security" style={{ display: "none" }}>
                      <div class="row">
                        <div class="col-md-12">
                          {/* <!-- Change Password --> */}
                          <div class="card mb-4">
                            <h5 class="card-header">Change Password</h5>
                            <div class="card-body">
                              <form id="formAccountSettings" class="fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
                                <div class="row">
                                  <div class="mb-3 col-md-6 form-password-toggle fv-plugins-icon-container">
                                    <label class="form-label" htmlFor="currentPassword">Current Password</label>
                                    <div class="input-group input-group-merge has-validation">
                                      <input class="form-control" type={passwordVisible.currentPassword ? "text" : "password"} name="currentPassword" id="currentPassword" placeholder="············" />
                                      <span className="input-group-text cursor-pointer" onClick={() => togglePasswordVisibility("currentPassword")}><i className={`ti ${passwordVisible.currentPassword ? 'ti-eye' : 'ti-eye-off'}`}></i></span>
                                    </div><div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="mb-3 col-md-6 form-password-toggle fv-plugins-icon-container">
                                    <label class="form-label" htmlFor="newPassword">New Password</label>
                                    <div class="input-group input-group-merge has-validation">
                                      <input class="form-control" type={passwordVisible.newPassword ? "text" : "password"} id="newPassword" name="newPassword" placeholder="············" />
                                      <span className="input-group-text cursor-pointer" onClick={() => togglePasswordVisibility("newPassword")}><i className={`ti ${passwordVisible.newPassword ? 'ti-eye' : 'ti-eye-off'}`}></i></span>
                                    </div><div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                  </div>

                                  <div class="mb-3 col-md-6 form-password-toggle fv-plugins-icon-container">
                                    <label class="form-label" htmlFor="confirmPassword">Confirm New Password</label>
                                    <div class="input-group input-group-merge has-validation">
                                      <input class="form-control" type={passwordVisible.confirmPassword ? "text" : "password"} name="confirmPassword" id="confirmPassword" placeholder="············" />
                                      <span className="input-group-text cursor-pointer" onClick={() => togglePasswordVisibility("confirmPassword")}><i className={`ti ${passwordVisible.confirmPassword ? 'ti-eye' : 'ti-eye-off'}`}></i></span>
                                    </div><div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                  </div>
                                  <div class="col-12 mb-4">
                                    <h6>Password Requirements:</h6>
                                    <ul class="ps-3 mb-0">
                                      <li class="mb-1">Minimum 8 characters long - the more, the better</li>
                                      <li class="mb-1">At least one lowercase character</li>
                                      <li>At least one number, symbol, or whitespace character</li>
                                    </ul>
                                  </div>
                                  <div>
                                    <button type="submit" class="btn btn-primary me-2 waves-effect waves-light">Save changes</button>
                                    <button type="reset" class="btn btn-label-secondary waves-effect">Cancel</button>
                                  </div>
                                </div>
                                <input type="hidden" /></form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>

        {/* Overlay */}
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </>
  );
}

export default Profile