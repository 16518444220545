import React from "react";
import Aside from "../../Layouts/sidebar/aside";
import Navbar from "../../Layouts/navbar";
import Footer from "../../Layouts/footer";
import { Link } from "react-router-dom";

export default function Spot() {

    const Beneficiaries = () => {

        var checkbox = document.querySelector('.form-check-input');
        var beneficiariesDiv = document.getElementById('beneficiariesDiv');

        if (checkbox.checked) {
            beneficiariesDiv.style.display = 'block';
        } else {
            beneficiariesDiv.style.display = 'none';
        }
    }

    return (
        <>
            <div class="layout-wrapper layout-content-navbar">
                <div class="layout-container">
                    <Aside />
                    <div class="layout-page">
                        <Navbar />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="d-flex justify-content-between">
                                    <h4 className="pt-3 mb-2">GBR 809995-Iconic Slate Ltd</h4>
                                    <Link to='/clients' className="btn btn-warning">Back</Link>
                                </div>
                                <div id="client-overview">
                                    <div class="card mt-2">
                                        <div className="card-header">
                                            <button className="btn btn-primary"> Spot</button>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-lg-6">
                                                <div class="account-details-table ps-2 card-datatable table-responsive">
                                                    <table style={{ width: "100%;" }}>
                                                        <tr>
                                                            <td>Buy</td>
                                                            <td><input type="text" className="form-control" placeholder='0' /></td>
                                                            <td>
                                                                <div>
                                                                    <select id="buyCurrency" className="select2 form-select">
                                                                        <option value="">EUR</option>
                                                                        <option value="usd">USD</option>
                                                                        <option value="pound">Pound</option>
                                                                        <option value="bitcoin">Bitcoin</option>
                                                                    </select>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: "280.172px;" }}>Sell</td>
                                                            <td><input type="text" className="form-control" placeholder='100' /></td>
                                                            <td>
                                                                <div>
                                                                    <select id="buyCurrency" className="select2 form-select">
                                                                        <option value="">GBP</option>
                                                                        <option value="usd">USD</option>
                                                                        <option value="pound">Pound</option>
                                                                        <option value="bitcoin">Bitcoin</option>
                                                                    </select>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ width: "280.172px;" }}>Market Rate</td>
                                                            <td>1</td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ width: "280.172px;" }}>Suggested Exchange Rate</td>
                                                            <td>1</td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ width: "280.172px;" }}>Re-Quoted Rate</td>
                                                            <td><input type="text" className="form-control" placeholder="Re-Quoted Rate" /></td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ width: "280.172px;" }}>Margin</td>
                                                            <td className="text-success">0.00</td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ width: "280.172px;" }}>Revenue</td>
                                                            <td className="text-success">0.00</td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ width: "280.172px;" }}>Value Date</td>
                                                            <td><input type="date" className="form-control" placeholder="09-02-2024" /></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 d-flex align-items-end card-datatable table-responsive">
                                                <table style={{ width: "100%" }}>
                                                    <tr>
                                                        <td>Total Payable Amount : </td>
                                                        <td className="text-warning">100.00 GBP</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Fees :</td>
                                                        <td className="text-warning">0.00 GBP</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Purchase Amount Remaining :</td>
                                                        <td className="text-warning">161.74 EUR</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="row mt-2 px-4">
                                            <div className="col-lg-12 d-flex flex-column align-items-start">
                                                <button className="border-0 bg-transparent" onClick={Beneficiaries}>
                                                    <input type="checkbox" className="form-check-input" /> Add Beneficiaries Now ?
                                                </button>
                                                <div id="beneficiariesDiv" className="m-3 p-3" style={{ display: 'none', width: "100%" }}>
                                                    <div style={{ width: "fit-content" }}>
                                                        <label htmlFor="buyCurrency" className="form-label">Select Beneficiaries</label>
                                                        <select id="buyCurrency" className="select2 form-select">
                                                            <option value="">Select</option>
                                                            <option>
                                                                <div className="d-flex flex-column">
                                                                    <h5>ALLMEND AGENCIES LTD.</h5>
                                                                    <h5>IE13246OF1325461658484</h5>
                                                                </div>
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <div class="card-datatable table-responsive mt-3">
                                                            <table class="datatables-category-list table border-top">
                                                                <thead>
                                                                    <tr className="bg-primary">
                                                                        <th className="text-white">Beneficiaries</th>
                                                                        <th className="text-white">Payment Amount (EUR)</th>
                                                                        <th className="text-white">Payment Referance</th>
                                                                        <th className="text-white">Transfer Fees (GBP)</th>
                                                                        <th className="text-white"> Settlement Amount</th>
                                                                        <th className="text-white">Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row m-3">
                                            <div className="col-lg-12 d-flex align-items-center">
                                                <button type="submit" className="btn btn-primary me-2">Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                        <div class="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
        </>
    );
}
