import React from 'react'
import { Link } from 'react-router-dom'

const RevenueCard = () => {
    return (
        <>
            <div className="row">
                <div className="col-xl-12 mb-4 col-md-6">
                    <div className="card h-100">
                        <div className="card-body mt-1">
                            <div className="row">
                                <div className="col-12 d-flex flex-column align-self-end">
                                    <div className="d-flex gap-2 align-items-center mb-2 pb-1 flex-wrap">
                                        <h3 className="mb-0">Revenue</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="border rounded p-3 mt-2">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="d-flex gap-2 align-items-center">
                                            <h6 className="mb-0">Today's</h6>
                                        </div>
                                        <h6 className="my-2 pt-1 d-flex">Є 545.69</h6>
                                        <div className="progress w-75" style={{ height: "4px" }}>
                                            <div className="progress-bar" role="progressbar" style={{ width: "65%" }} aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="d-flex gap-2 align-items-center">
                                            <h6 className="mb-0">January</h6>
                                        </div>
                                        <h6 className="my-2 pt-1 d-flex">Є 256.34</h6>
                                        <div className="progress w-75" style={{ height: "4px" }}>
                                            <div className="progress-bar bg-info" role="progressbar" style={{ width: "80%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="d-flex gap-2 align-items-center">
                                            <h6 className="mb-0">February</h6>
                                        </div>
                                        <h6 className="my-2 pt-1 d-flex">Є 724.19</h6>
                                        <div className="progress w-75" style={{ height: "4px" }}>
                                            <div className="progress-bar bg-danger" role="progressbar" style={{ width: "65%" }} aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!--/ Revenue --> */}

                {/*<!-- Deals --> */}
                <div className="col-xl-12 mb-4 col-md-6">
                    <div className="card h-100">
                        <div className="card-header d-flex justify-content-between pb-1">
                            <h4 className="mb-0 card-title">Deals</h4>
                            <div className="dropdown">
                                <button className="btn p-0" type="button" id="totalEarning" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="ti ti-dots-vertical ti-sm text-muted"></i>
                                </button>
                                <div className="dropdown-menu dropdown-menu-end" aria-labelledby="totalEarning">
                                    <Link className="dropdown-item" to="">
                                        View More
                                    </Link>
                                    <Link className="dropdown-item" to="">
                                        Delete
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div id="totalEarningChart"></div>
                            <div className="d-flex align-items-center  my-4">
                                <div className="badge rounded bg-label-danger p-2 me-3 ">
                                    <i className="ti ti-brand-paypal ti-sm"></i>
                                </div>
                                <div className="d-flex justify-content-between w-100 gap-2 align-items-center">
                                    <div className="me-2">
                                        <h6 className="mb-0">Number of Deal's Today</h6>

                                    </div>
                                    <p className="mb-0 text-success">$126</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-center  my-4">
                                <div className="badge rounded bg-label-primary p-2 me-3">
                                    <i className="ti ti-currency-dollar ti-sm"></i>
                                </div>
                                <div className="d-flex justify-content-between w-100 gap-2 align-items-center">
                                    <div className="me-2">
                                        <h6 className="mb-0">Number of Unpaid Deals</h6>
                                    </div>
                                    <p className="mb-0 text-success">+$98</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-center  my-4">
                                <div className="badge rounded bg-label-primary p-2 me-3 ">
                                    <i className="ti ti-currency-dollar ti-sm"></i>
                                </div>
                                <div className="d-flex justify-content-between w-100 gap-2 align-items-center">
                                    <div className="me-2">
                                        <h6 className="mb-0">Forward Deposits Due</h6>
                                    </div>
                                    <p className="mb-0 text-success">+$98</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RevenueCard