import React from 'react'
import Feilds from './feilds'

const spotProfitMargin = () => {
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card my-4">
                        <div className="card-body">
                            <form id="formAccountSettings" >

                                <Feilds from={"From GBP"} to={"TO GBP"} margin={"Margin Percentage"} fromID={"FromGBP"} toID={"TOGBP"} marginID={"MarginPercentage"} />
                                <Feilds from={"From GBP"} to={"TO GBP"} margin={"Margin Percentage"} fromID={"FromGBP"} toID={"TOGBP"} marginID={"MarginPercentage"} />

                                <div className="row mt-2">
                                    <div className="col-lg-12 d-flex align-items-center">
                                        <button className="btn btn-primary me-2">Save changes</button>
                                        <button type="reset" className="btn btn-label-secondary">Cancel</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default spotProfitMargin