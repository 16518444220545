import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import DropdownList from "./DropdownList";
import "./sidebar.css";

function Aside() {
  const location = useLocation();
  const [sidebarWidth, setSidebarWidth] = useState(window.innerWidth > 1200 ? 250 : 50);

  useEffect(() => {
    const handleResize = () => {
      setSidebarWidth(window.innerWidth > 1200 ? 250 : 50);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isActive = (pathname) => {
    return location.pathname === pathname;
  };

  return (
    <>
      <aside id="layout-menu" className={`hm-layout-menu menu-vertical menu bg-menu-theme ${sidebarWidth === 50 ? 'collapsed' : ''}`} style={{ width: sidebarWidth + "px" }}>

        <div className="app-brand demo ps-0">

            <div className="d-flex align-items-center"> 
              <div className="m-0 pe-3 equals-sign">{sidebarWidth === 50 ? "=" : ""}</div>
              <h5 className={`mb-0 ${sidebarWidth === 50 ? 'link-title text-black ' : ''}`}>Equals Money</h5>
            </div>

          {window.innerWidth > 1200 && (
            <button className="layout-menu-toggle menu-link text-large ms-auto border-0 bg-transparent" onClick={() => setSidebarWidth(sidebarWidth === 250 ? 50 : 250)}>
              <i className="ti menu-toggle-icon d-none d-xl-block ti-sm align-middle"></i>
              <i className="ti ti-x d-block d-xl-none ti-sm align-middle"></i>
            </button>
          )}
        </div>

        <div className="menu-inner-shadow"></div>

        <ul className="menu-inner py-1">
          <li className={`menu-item ${isActive("/dashboard") ? "active" : ""}`}>
            <Link to="/dashboard" className="menu-link">
              <i className="menu-icon tf-icons ti ti-smart-home"></i>
              <div className={sidebarWidth === 50 ? 'link-title text-black ' : ''} data-i18n="Dashboard">Dashboard</div>
            </Link>
          </li>
          <li
            className={`menu-item ${isActive("/clients") ||
              isActive("/manage-clients") ||
              isActive("/clients/customer-user-trade") ||
              isActive("/clients/spot-trades")
              ? "active"
              : ""
              }`}
          >
            <Link to="/manage-clients" className="menu-link">
              <i className="menu-icon tf-icons ti ti-users"></i>
              <div className={sidebarWidth === 50 ? 'link-title text-black ' : ''} data-i18n="Clients">Clients</div>
            </Link>
          </li>

          <DropdownList
            className={`menu-item ${isActive("/deals") ||
              isActive("/assign-beneficiaries") ||
              isActive("/deals/deal-overview") ||
              isActive("/deals/unpaid-spot-deals") ||
              isActive("/deals/assign-beneficiaries")
              ? "active"
              : ""
              }`}
            icon={<i className="menu-icon tf-icons ti ti-file-dollar"></i>}
            link1={"/deals"}
            link2={"/deals/assign-beneficiaries"}
            link3={"/deals/unpaid-spot-deals"}
            data1={"All Deals"}
            data2={"Assign Beneficiaries"}
            data3={"Unpaid Spot Deals (15)"}
            title={"Deals"}
            titleClass={sidebarWidth === 50 ? 'link-title text-black ' : ''}
          />

          <DropdownList
            className={`menu-item ${isActive("/compliance/tps-verification") ? "active" : ""
              }`}
            icon={<i className="menu-icon tf-icons ti ti-chart-pie"></i>}
            link1={"/compliance/tps-verification"}
            data1={"TPS Verification"}
            title={"Compliance"}
          />

          <DropdownList
            className={`menu-item ${isActive("/reports/drawdown-revenue") ? "active" : ""
              }`}
            icon={<i className="menu-icon tf-icons ti ti-files"></i>}
            link1={"/reports/drawdown-revenue"}
            data1={"Drawdown Revenue"}
            title={"Reports"}
          />

          <li
            className={`menu-item ${isActive("/account") ? "active" : ""}`}
            style={{ display: isActive("/account") ? "block" : 'none' }}
          >
            <Link to="/account" className="menu-link">
              <i className="menu-icon tf-icons ti ti-user"></i>
              <div className={sidebarWidth === 50 ? 'link-title text-black ' : ''} data-i18n="Account">Account</div>
            </Link>
          </li>
        </ul>

      </aside>
    </>
  );
}


export default Aside;
