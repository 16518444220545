import React from 'react';
import Check from '../../../../assets/img/icons/icons8-check-mark-20.png';
import { Link } from 'react-router-dom';

const ClientWallet = () => {

    const Head = [{
        id : 1 ,
        no: '1',
        AccountNumber: 'AccountNumber',
        NickName: 'Nick Name',
        Balance: 'Balance',
        Currency: 'Currency',
        Active: 'Active'
    }];

    const TableData = [{
        id: 1,
        no: '1',
        AccountNumber: 'USD656435',
        NickName: 'Wallet_USD',
        Balance: '0.00',
        Currency: 'USD',
    },
    {
        id: 2,
        no: '2',
        AccountNumber: 'GBP656435',
        NickName: 'GBP',
        Balance: '0.00',
        Currency: 'GBP',
    },
    {
        id: 3,
        no: '3',
        AccountNumber: 'EUR656435',
        NickName: 'Wallet_EUR',
        Balance: '0.00',
        Currency: 'EUR',
    }
    ]

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card my-3 p-3">
                        <div className="card-datatable table-responsive ps-3">
                            <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                <table className="datatables-customers hm-table table border-top dataTable no-footer dtr-column hm-table-color" id="DataTables_Table_0">
                                    <thead className="table-head-bg">
                                        {Head.map((item) => {
                                            return (
                                                <tr key={item.id}>
                                                    <th>{item.no}</th>
                                                    <th>{item.AccountNumber}</th>
                                                    <th>{item.NickName}</th>
                                                    <th>{item.Balance}</th>
                                                    <th>{item.Currency}</th>
                                                    <th>{item.Active}</th>
                                                </tr>
                                            )
                                        })}
                                    </thead>

                                    <tbody>
                                        {TableData.map((item) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>{item.no}</td>
                                                    <td><Link to=''>{item.AccountNumber}</Link></td>
                                                    <td>{item.NickName}</td>
                                                    <td>{item.Balance}</td>
                                                    <td>{item.Currency}</td>
                                                    <td><img src={Check} alt="" /></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default ClientWallet;