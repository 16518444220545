import React from 'react';
import Check from '../../../../assets/img/icons/icons8-check-mark-20.png';

const ClientBeneficiaries = () => {

    const tableData = [
        {
            id: 1,
            no: '1',
            name: 'ALLMED AGENCIES LTD',
            country: 'Irland',
            account: 'IE3546532168454838',
            swiftCode: 'BOFIE2D',
            sortCode: '',
            routing: '',
            currency: 'EUR',
            createdby: 'Dagmara Pritchett',
            createdDate: '06-01-2020 10:04',
        },
        {
            id: 2,
            no: '2',
            name: 'BARCAMP LTD',
            country: 'Brazil',
            account: 'IE3546532168454838',
            swiftCode: 'BOFIE2D',
            sortCode: '',
            routing: '',
            currency: 'USD',
            createdby: 'Olga Backina',
            createdDate: '07-01-2020 10:04',
        },
        {
            id: 3,
            no: '3',
            name: 'COELHO DA SILVA SA',
            country: 'Portugal',
            account: 'IE3546532168454838',
            swiftCode: 'BOFIE2D',
            sortCode: '203590',
            routing: '',
            currency: 'EUR',
            createdby: 'Olga Backina',
            createdDate: '08-01-2020 10:04',
        },
        {
            id: 4,
            no: '4',
            name: 'ALLMED AGENCIES LTD',
            country: 'Irland',
            account: 'IE3546532168454838',
            swiftCode: 'BOFIE2D',
            sortCode: '',
            routing: '',
            currency: 'EUR',
            createdby: 'Dagmara Pritchett',
            createdDate: '06-01-2020 10:04',
        }
    ];


    return (
        <>
            <div className="row" >
                <div className="col-md-12">
                    <div className="card my-4">
                        {/* <!-- Account --> */}
                        <div className="card-body">
                            <form id="formAccountSettings" >
                                <div className="row">
                                    <div className="mb-3 col-lg-3">

                                        <label htmlFor="benficiariesName" className="form-label">Beneficiaries Name</label>
                                        <input className="form-control" type="text" id="benficiariesName" name="benficiariesName" />
                                    </div>

                                    <div className="mb-3 col-lg-3">
                                        <label className="form-label" htmlFor="accountNumber">IBAN / Account No</label>
                                        <div className="input-group input-group-merge">
                                            <input type="text" id="accountNumber" name="accountNumber" className="form-control" />
                                        </div>
                                    </div>

                                    <div className="mb-3 col-lg-3">
                                        <label className="form-label" htmlFor="Country">Select Country</label>
                                        <select id="Country" className="select2 form-select">
                                            <option value="">Select</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Belarus">Belarus</option>
                                            <option value="Brazil">Brazil</option>
                                        </select>
                                    </div>

                                    <div className="mb-3 col-lg-3">
                                        <label htmlFor="Currency" className="form-label">Select Currency</label>
                                        <select id="Currency" className="select2 form-select">
                                            <option value="">Select</option>
                                            <option value="usd">USD</option>
                                            <option value="euro">Euro</option>
                                            <option value="pound">Pound</option>
                                            <option value="bitcoin">Bitcoin</option>
                                        </select>
                                    </div>

                                    <div className="col-lg-3 mb-3 align-items-end">
                                        <label htmlFor="Currency" className="form-label">Select User Option</label>
                                        <select id="Currency" className="select2 form-select">
                                            <option value="">Select</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-lg-12 d-flex align-items-center">
                                        <button type="submit" className="btn btn-primary me-2">Search</button>
                                        <button type="reset" className="btn btn-label-secondary">Reset</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {/* <!-- /Account --> */}
                    </div>
                </div>
                {/* Table */}
                <div className="row">
                    <div className="col-md-12">
                        <div className="card mb-4">
                            {/* <!-- Account --> */}
                            <div className="card-body">
                                <div className="card-datatable table-responsive ps-3">
                                    <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                        <table className="datatables-customers hm-table table border-top dataTable no-footer dtr-column hm-table-color" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info">
                                            <thead className="table-head-bg" >
                                                <tr>
                                                    <th>No</th>
                                                    <th style={{width : "300px"}}>Beneficiaries</th>
                                                    <th>Country</th>
                                                    <th>IBAN / Account No</th>
                                                    <th>SWIFT Code / BIC</th>
                                                    <th>SORT Code</th>
                                                    <th>Routing / ABA Number</th>
                                                    <th>Currency</th>
                                                    <th>Created By</th>
                                                    <th>Created Date</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* Map over table data */}
                                                {tableData.map((rowData) => (
                                                    <tr key={rowData.id}>
                                                        <td>{rowData.no}</td>
                                                        <td><img src={Check} alt="" /> <small> {rowData.name}</small></td>
                                                        <td>{rowData.country}</td>
                                                        <td><small>{rowData.account}</small></td>
                                                        <td>{rowData.swiftCode}</td>
                                                        <td>{rowData.sortCode}</td>
                                                        <td>{rowData.routing}</td>
                                                        <td>{rowData.currency}</td>
                                                        <td><small>{rowData.createdby}</small></td>
                                                        <td><small>{rowData.createdDate}</small></td>
                                                        <td>
                                                            <i class="ti ti-pencil me-1 text-success cursor-pointer"></i>
                                                            <i class="ti ti-trash me-1 text-danger cursor-pointer"></i>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClientBeneficiaries;