import React from 'react'
import { Link } from 'react-router-dom'

const TransactionCard = () => {
    return (
        <>
            <div className="card">
                <div className="card-header d-flex justify-content-between">
                    <div className="card-title m-0">
                        <h4 className="mb-0">Transactions</h4>
                        <small className="text-muted">Overview</small>
                    </div>
                    <div className="dropdown">
                        <button className="btn p-0" type="button" id="earningReportsTabsId" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="ti ti-dots-vertical ti-sm text-muted"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-end" aria-labelledby="earningReportsTabsId">
                            <Link className="dropdown-item" to="">
                                View More
                            </Link>
                            <Link className="dropdown-item" to="">
                                Delete
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 hm-transaction-tiles-1  pb-3  d-flex flex-nowrap justify-content-center">
                            <div className=" btn d-flex flex-column align-items-center justify-content-center">
                                <div className="badge bg-label-secondary rounded p-2">
                                    <i className="ti ti-currency-dollar ti-sm text-danger"></i>
                                </div>
                                <h5 className="tab-widget-title mb-0 mt-2 text-danger">
                                    Є 61,432.00
                                </h5>
                                <h6 className="tab-widget-title mb-0 mt-2">
                                    January Turnover
                                </h6>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 hm-transaction-tiles-2  pb-3 d-flex flex-nowrap justify-content-center">
                            <div className=" btn d-flex flex-column align-items-center justify-content-center">
                                <div className="badge bg-label-secondary rounded p-2">
                                    <i className="ti ti-chart-bar ti-sm text-primary"></i>
                                </div>
                                <h5 className="tab-widget-title mb-0 mt-2 text-white">
                                    Є 0.00
                                </h5>
                                <h6 className="tab-widget-title mb-0 mt-2 text-white">
                                    Today's Turnover
                                </h6>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 hm-transaction-tiles-3  pb-3  d-flex flex-nowrap justify-content-center">
                            <div className=" btn d-flex flex-column align-items-center justify-content-center">
                                <div className="badge bg-label-secondary rounded p-2">
                                    <i className="ti ti-chart-pie-2 ti-sm text-info"></i>
                                </div>
                                <h5 className="tab-widget-title mb-0 mt-2 text-info">
                                    Є 61,127.39
                                </h5>
                                <h6 className="tab-widget-title mb-0 mt-2">
                                    February Turnover
                                </h6>
                            </div>
                        </div>
                    </div>

                    <div className="tab-content p-0 ms-0 ms-sm-2">
                        <div className="tab-pane fade show active" id="navs-orders-id" role="tabpanel">
                            <div id="earningReportsTabsOrders"></div>
                        </div>
                        <div className="tab-pane fade" id="navs-sales-id" role="tabpanel">
                            <div id="earningReportsTabsSales"></div>
                        </div>
                        <div className="tab-pane fade" id="navs-profit-id" role="tabpanel">
                            <div id="earningReportsTabsProfit"></div>
                        </div>
                        <div className="tab-pane fade" id="navs-income-id" role="tabpanel">
                            <div id="earningReportsTabsIncome"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TransactionCard