import React from "react";
import { Link } from "react-router-dom";
import Check from '../../../../../assets/img/icons/icons8-check-mark-20.png';
import Unverified from '../../../../../assets/img/icons/icons8-cross-20.png';

const Table = () => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="card-datatable table-responsive ps-3">
          <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
            <h3 className="ps-3">User Overview</h3>
            <table className="datatables-customers hm-table table border-top dataTable no-footer dtr-column hm-table-color" id="DataTables_Table_0">
              <thead className="table-head-bg">
                <tr>
                  <th className="sorting">Trade</th>
                  <th className="sorting">Name</th>
                  <th className="sorting">User Name</th>
                  <th className="sorting">Email</th>
                  <th className="sorting">Next Verification</th>
                  <th className="sorting">Active</th>
                  <th className="sorting">Online Access</th>
                  <th className="sorting">Rates</th>
                  <th className="sorting">Deal Booking</th>
                  <th className="sorting">Dual Auth</th>
                  <th className="sorting">User Type</th>
                  <th className="sorting">KYC Status</th>
                  <th className="sorting">Beneficiaries</th>
                  <th className="sorting">Spot Trades</th>
                  <th className="sorting">Forward Trades</th>
                  <th className="sorting">Registered No</th>
                  <th className="sorting">Action</th>
                </tr>
              </thead>

              <tbody className="text-center">
                <tr>
                  <td>
                    <div className="badge rounded-pill bg-label-primary p-2 me-3 rounded">
                      <Link to="/clients/customer-user-trade">
                        <i className="ti ti-currency-dollar ti-sm text-primary"></i>
                      </Link>
                    </div>
                  </td>
                  <td className="sorting"><Link to="">Syed Hammad</Link></td>
                  <td className="sorting sorting_desc">Emmy</td>
                  <td className="sorting">Email</td>
                  <td className="sorting">Next Verification</td>
                  <td className="sorting"><img src={Check} alt="" /></td>
                  <td className="sorting"><img src={Check} alt="" /></td>
                  <td className="sorting"><img src={Check} alt="" /></td>
                  <td className="sorting"><img src={Check} alt="" /></td>
                  <td className="sorting"><img src={Unverified} alt="" /></td>
                  <td className="sorting">Full Access</td>
                  <td className="sorting" style={{ width: "80px" }}><img src={Check} alt="" />Verified</td>
                  <td className="sorting"><Link to="/">1</Link></td>
                  <td className="sorting"><Link to="/clients/spot-trades">2</Link></td>
                  <td className="sorting"><Link to="/">0</Link></td>
                  <td className="sorting">Registered No</td>
                  <td className="sorting"><i className="ti ti-pencil me-1 text-success cursor-pointer"></i></td>
                </tr>

                <tr>
                  <td>
                    <div className="badge rounded-pill bg-label-primary p-2 me-3 rounded">
                      <Link to="/customer-user-trade">
                        <i className="ti ti-currency-dollar ti-sm text-primary"></i>
                      </Link>
                    </div>
                  </td>
                  <td className="sorting"><Link to="">Syed Hammad</Link></td>
                  <td className="sorting sorting_desc">Emmy</td>
                  <td className="sorting">Email</td>
                  <td className="sorting">Next Verification</td>
                  <td className="sorting"><img src={Check} alt="" /></td>
                  <td className="sorting"><img src={Check} alt="" /></td>
                  <td className="sorting"><img src={Check} alt="" /></td>
                  <td className="sorting"><img src={Check} alt="" /></td>
                  <td className="sorting"><img src={Unverified} alt="" /></td>
                  <td className="sorting">User Type</td>
                  <td className="sorting" style={{ width: "80px" }}><img src={Check} alt="" />Verified</td>
                  <td className="sorting"><Link to="/">2</Link></td>
                  <td className="sorting"><Link to="/clients/spot-trades">13</Link></td>
                  <td className="sorting"><Link to="/">0</Link></td>
                  <td className="sorting">Registered No</td>
                  <td className="sorting"><i className="ti ti-pencil me-1 text-success cursor-pointer"></i></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
