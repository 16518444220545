import React from "react";
import Aside from "../Layouts/sidebar/aside";
import Navbar from "../Layouts/navbar";
import Footer from "../Layouts/footer";
import Check from '../../../assets/img/icons/icons8-check-mark-20.png';
import Unverified from '../../../assets/img/icons/icons8-cross-20.png';
import { Link } from "react-router-dom";

export default function ManageClients() {


    return (
        <>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <Aside />
                    <div className="layout-page">
                        <Navbar />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <h3 className="mb-2 ps-3">Manage Clients</h3>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card mb-4">
                                            <div className="card-body">
                                                <form id="formAccountSettings" method="GET">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="row">

                                                                <div className="mb-3 col-lg-3">
                                                                    <label htmlFor="clientName" className="form-label">Client Name</label>
                                                                    <input className="form-control" type="text" id="clientName" name="clientName" placeholder="..."  />
                                                                </div>

                                                                <div className="mb-3 col-lg-3">
                                                                    <label className="form-label" htmlFor="clientNumber">Client Code</label>
                                                                    <div className="input-group input-group-merge">
                                                                        <input type="text" id="clientNumber" name="clientNumber" className="form-control" placeholder="GBR 555 0111" />
                                                                    </div>
                                                                </div>

                                                                <div className="mb-3 col-lg-3">
                                                                    <label htmlFor="contant-name" className="form-label">Contact Name</label>
                                                                    <input type="text" className="form-control" id="contact-name" name="contact-name" placeholder="0301-2345678" />
                                                                </div>

                                                                <div className="mb-3 col-lg-3">
                                                                    <label htmlFor="phone-name" className="form-label">Phone Name</label>
                                                                    <input type="text" className="form-control" id="phone-name" name="phone-name" placeholder="0301-2345678" />
                                                                </div>

                                                                <div className="mb-3 col-lg-3">
                                                                    <label className="form-label" htmlFor="email">Email</label>
                                                                    <div className="input-group input-group-merge">
                                                                        <input type="email" id="email" name="email" className="form-control" placeholder="@gmail.com" />
                                                                    </div>
                                                                </div>

                                                                <div className="mb-3 col-lg-3">
                                                                    <label className="form-label" htmlFor="register-from">Registered From</label>
                                                                    <div className="input-group input-group-merge">
                                                                        <input type="date" id="register-from" name="register-from" className="form-control" />
                                                                    </div>
                                                                </div>

                                                                <div className="mb-3 col-lg-3">
                                                                    <label className="form-label" htmlFor="register-to">Registered to</label>
                                                                    <div className="input-group input-group-merge">
                                                                        <input type="date" id="register-to" name="register-to" className="form-control" />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row mt-2">
                                                        <div className="col-lg-12 d-flex align-items-center">
                                                            <button type="submit" className="btn btn-primary me-2">Search</button>
                                                            <button type="reset" className="btn btn-label-secondary">Reset</button>
                                                        </div>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card-datatable table-responsive ps-3">
                                            <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                                <table className="datatables-customers hm-table table border-top dataTable no-footer dtr-column hm-table-color" id="DataTables_Table_0">
                                                    <thead className="table-head-bg">
                                                        <tr>
                                                            <th className="sorting">No</th>
                                                            <th className="sorting">Client Code</th>
                                                            <th className="sorting">Client Name</th>
                                                            <th className="sorting">Contacts</th>
                                                            <th className="sorting">Telephone Number</th>
                                                            <th className="sorting">Spot</th>
                                                            <th className="sorting">Forward</th>
                                                            <th className="sorting">Beneficiaries</th>
                                                            <th className="sorting">Active</th>
                                                            <th className="sorting">Spot Trading</th>
                                                            <th className="sorting">Forward Trading</th>
                                                            <th className="sorting">KYC Status</th>
                                                            <th className="sorting">Customer Type</th>
                                                            <th className="sorting">Registered On</th>
                                                            <th className="sorting">Account Manager</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="text-center">
                                                        <tr>
                                                            <td>1</td>
                                                            <td className="sorting"><Link to="/clients">GBP56468</Link></td>
                                                            <td className="sorting"><Link to=""><small>Iconic State LTD</small></Link></td>
                                                            <td className="sorting"><Link to="">2</Link></td>
                                                            <td className="sorting"><small>-44 12702445025</small></td>
                                                            <td className="sorting"><Link to="">58</Link></td>
                                                            <td className="sorting"><Link to="">2</Link></td>
                                                            <td className="sorting"><Link to="">16</Link></td>
                                                            <td className="sorting"><img src={Check} alt="" /></td>
                                                            <td className="sorting"><img src={Check} alt="" /></td>
                                                            <td className="sorting"><img src={Unverified} alt="" /></td>
                                                            <td className="sorting"><small><img src={Check} alt="" /> Verified</small></td>
                                                            <td className="sorting">Business</td>
                                                            <td className="sorting">10-10-2319</td>
                                                            <td className="sorting"><small>Jacob Arbury</small></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="row mx-2">
                                                    <div className="col-sm-12 col-md-6">
                                                        <div className="dataTables_info" id="DataTables_Table_0_info">
                                                            Showing 0 to 0 of 0 entries
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6">
                                                        <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                                                            <ul className="pagination">
                                                                <li className="paginate_button page-item previous disabled" id="DataTables_Table_0_previous">
                                                                    <Link aria-controls="DataTables_Table_0" to="#" aria-disabled="true" role="link" data-dt-idx="previous" tabIndex={0} className="page-link">
                                                                        Previous
                                                                    </Link>
                                                                </li>
                                                                <li className="paginate_button page-item next disabled" id="DataTables_Table_0_next">
                                                                    <Link to={"#"} className="page-link">
                                                                        Next
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
        </>
    )
}