import React from "react";
import "../../../assets/vendor/css/pages/cards-advance.css";
import Aside from '../Layouts/sidebar/aside';
import Navbar from '../Layouts/navbar';
import WelcomeCard from "./subComps/welcomeCard";
import ApplicationCard from "./subComps/applicationCard";
import RevenueCard from "./subComps/revenueCard";
import TransactionCard from "./subComps/transactionCard";


function Dashboard() {

  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Aside />
          <div className="layout-page">
            <Navbar />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row">
                  <div className="col-xl-4 mb-4 col-lg-12 col-12">
                    <WelcomeCard />
                  </div>
                  <div className="col-xl-8 mb-4 col-lg-12 col-12">
                    <ApplicationCard />
                  </div>
                  <div className="col-xl-4 col-12">
                    <RevenueCard />
                  </div>
                  <div className="col-xl-8">
                    <TransactionCard />
                  </div>
                </div>
              </div>
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        {/* Overlay */}
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </>
  );
}
export default Dashboard;
