import React from 'react';
import { Link } from "react-router-dom";
import Select from 'react-select'
import Button from 'react-bootstrap/Button';
import MyVerticallyCenteredModal from '../../modal/modal';

const ClientDeals = () => {

    const [modalShow, setModalShow] = React.useState(false);

    const Options = [
        { value: 'Balance Cancelled', label: 'Balance Cancelled' },
        { value: 'Cancelled', label: 'Cancelled' },
        { value: 'Completed', label: 'Completed' },
        { value: 'Fund Received', label: 'Fund Received' },
        { value: 'Awaiting Funds', label: 'Awaiting Funds' }
    ]

    return (
        <>
            {/* Deals */}
            <div>
                <div className="row" >
                    <h3 className="mb-2 pt-2 ps-4">All Deals</h3>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card mb-4">
                                {/* <!-- Account --> */}
                                <div className="card-body">
                                    <form id="formAccountSettings" >
                                        <div className="row">
                                            <div className="mb-3 col-lg-3">

                                                <label htmlFor="customerName" className="form-label">Customer Name</label>
                                                <input className="form-control" type="text" id="customerName" name="customerName" placeholder="John" />
                                            </div>

                                            <div className="mb-3 col-lg-3">
                                                <label className="form-label" htmlFor="clientNumber">Client Code</label>
                                                <div className="input-group input-group-merge">
                                                    <input type="text" id="clientNumber" name="clientNumber" className="form-control" placeholder="GBR 555 0111" />
                                                </div>
                                            </div>

                                            <div className="mb-3 col-lg-3">
                                                <label htmlFor="dealNo" className="form-label">Deal No</label>
                                                <input type="text" className="form-control" id="deal No" name="dealNo" placeholder="SP1234587" />
                                            </div>

                                            <div className="mb-3 col-lg-3">
                                                <label htmlFor="status" className="form-label">Select Status</label>
                                                <input type="text" className="form-control" id="status" name="status" />
                                            </div>

                                            <div className="mb-3 col-lg-3">

                                                <label className="form-label" htmlFor="sellCurrency">Select sell Currency</label>
                                                <select id="sellCurrency" className="select2 form-select">
                                                    <option value="">Select</option>
                                                    <option value="Australia">Australia</option>
                                                    <option value="Bangladesh">Bangladesh</option>
                                                    <option value="Belarus">Belarus</option>
                                                    <option value="Brazil">Brazil</option>
                                                    <option value="Canada">Canada</option>
                                                    <option value="China">China</option>
                                                    <option value="France">France</option>
                                                    <option value="Germany">Germany</option>
                                                    <option value="India">India</option>
                                                    <option value="Indonesia">Indonesia</option>
                                                    <option value="Israel">Israel</option>
                                                    <option value="Italy">Italy</option>
                                                    <option value="Japan">Japan</option>
                                                    <option value="Korea">Korea, Republic of</option>
                                                    <option value="Mexico">Mexico</option>
                                                    <option value="Philippines">Philippines</option>
                                                    <option value="Russia">Russian Federation</option>
                                                    <option value="South Africa">South Africa</option>
                                                    <option value="Thailand">Thailand</option>
                                                    <option value="Turkey">Turkey</option>
                                                    <option value="Ukraine">Ukraine</option>
                                                    <option value="United Arab Emirates">United Arab Emirates</option>
                                                    <option value="United Kingdom">United Kingdom</option>
                                                    <option value="United States">United States</option>
                                                </select>

                                            </div>

                                            <div className="mb-3 col-lg-3">
                                                <label htmlFor="buyCurrency" className="form-label">Select Buy Currency</label>
                                                <select id="buyCurrency" className="select2 form-select">
                                                    <option value="">Select</option>
                                                    <option value="usd">USD</option>
                                                    <option value="euro">Euro</option>
                                                    <option value="pound">Pound</option>
                                                    <option value="bitcoin">Bitcoin</option>
                                                </select>
                                            </div>

                                            <div class="col-lg-6 mb-3">
                                                <label className="form-label">Select Status</label>
                                                <Select
                                                    isMulti
                                                    options={Options}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                />
                                            </div>

                                            <div className="col-lg-3 mb-3 d-flex align-items-end">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" />
                                                    <label class="form-check-label" htmlFor="defaultCheck3"> Search Group Clients </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 d-flex justify-content-end gap-3">
                                                <div className="d-flex align-items-end mb-2">
                                                    <h5>Date :</h5>
                                                </div>
                                                <div className="mb-3 col-md-2">
                                                    <label htmlFor="status" className="form-label">From</label>
                                                    <input type="date" className="form-control" id="status" name="status" />
                                                </div>
                                                <div className="mb-3 col-md-2">
                                                    <label htmlFor="status" className="form-label">To</label>
                                                    <input type="date" className="form-control" id="status" name="status" />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row mt-2">
                                            <div className="col-lg-12 d-flex align-items-center">
                                                <button type="submit" className="btn btn-primary me-2">Save changes</button>
                                                <button type="reset" className="btn btn-label-secondary">Cancel</button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                                {/* <!-- /Account --> */}
                            </div>
                        </div>
                    </div>
                    {/* Table */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card mb-4">
                                {/* <!-- Account --> */}
                                <div className="card-body">
                                    <div className="card-datatable table-responsive ps-3">
                                        <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                            <table className="datatables-customers hm-table table border-top dataTable no-footer dtr-column hm-table-color" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info">
                                                <thead className="table-head-bg" >
                                                    <tr>
                                                        <th className="sorting" rowSpan={1} colSpan={1} style={{ width: "20px" }} >No</th>

                                                        <th className="sorting" rowSpan={1} colSpan={1}  >
                                                            Deal No
                                                        </th>
                                                        <th className="sorting" rowSpan={1} colSpan={1} style={{ width: "250px" }} aria-label="Country: activate to sort column ascending" >
                                                            Client Code
                                                        </th>
                                                        <th className="sorting" rowSpan={1} colSpan={1} >
                                                            Trade date
                                                        </th>
                                                        <th className="sorting" rowSpan={1} colSpan={1} >
                                                            Value Date
                                                        </th>
                                                        <th className="sorting" rowSpan={1} colSpan={1} >
                                                            Beneficiaries
                                                        </th>
                                                        <th className="sorting" rowSpan={1} colSpan={1} >
                                                            Buy Amount
                                                        </th>
                                                        <th className="sorting" rowSpan={1} colSpan={1} >
                                                            Client Rate
                                                        </th>
                                                        <th className="sorting" rowSpan={1} colSpan={1} >
                                                            Sell Amount
                                                        </th>
                                                        <th className="sorting" rowSpan={1} colSpan={1} >
                                                            Remittance Fee
                                                        </th>
                                                        <th className="sorting" rowSpan={1} colSpan={1} >
                                                            Payable Amount
                                                        </th>
                                                        <th className="sorting" rowSpan={1} colSpan={1} >
                                                            Boooked Rate
                                                        </th>
                                                        <th className="sorting" rowSpan={1} colSpan={1} >
                                                            Revenue
                                                        </th>
                                                        <th className="sorting" rowSpan={1} colSpan={1} >
                                                            Fx Margin
                                                        </th>
                                                        <th className="sorting" rowSpan={1} colSpan={1} >
                                                            Status
                                                        </th>
                                                        <th className="sorting" rowSpan={1} colSpan={1} >
                                                            Booked By
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* first Row */}
                                                    <tr>
                                                        <td className="sorting" rowSpan={1} colSpan={1} style={{ width: "20px" }} >1</td>

                                                        <td className="sorting" rowSpan={1} colSpan={1}  >
                                                            <Link to="" >SP 240240  </Link>
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1} aria-label="Country: activate to sort column ascending" >
                                                            <Link to="" >GBP 28563210</Link>
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1} >
                                                            09-02-2024
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1} >
                                                            09-02-2024
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1} style={{ width: "30px" }}>
                                                            <Button className=" bg-label-info m-0 " onClick={() => setModalShow(true)}>
                                                                1
                                                            </Button>

                                                            <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)}
                                                            />
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1}>
                                                            83,712.00 EUR
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1} >
                                                            1,167.00
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1} style={{ width: "00px" }}>
                                                            71,250.00
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1} >
                                                            0.00
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1} style={{ width: "300px" }}>
                                                            71,234 GBP
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1} >
                                                            1,170,306
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1} >
                                                            202.63 GBP
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1} >
                                                            0.28%
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1} >
                                                            Awaiting Funds
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1}>
                                                            Jacon Arbury
                                                        </td>
                                                    </tr>
                                                    {/* second Row */}
                                                    <tr>
                                                        <td className="sorting" rowSpan={1} colSpan={1} style={{ width: "20px" }} >2</td>

                                                        <td className="sorting" rowSpan={1} colSpan={1}  >
                                                            <Link to="" >SP 240240  </Link>
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1} aria-label="Country: activate to sort column ascending" >
                                                            <Link to="" >GBP 28563210</Link>
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1} >
                                                            09-02-2024
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1} >
                                                            09-02-2024
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1} style={{ width: "30px" }}>
                                                            <Button className=" bg-label-info m-0 " onClick={() => setModalShow(true)}>
                                                                2
                                                            </Button>

                                                            <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)}
                                                            />
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1}>
                                                            83,712.00 EUR
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1} >
                                                            1,167.00
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1} style={{ width: "00px" }}>
                                                            71,250.00
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1} >
                                                            0.00
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1} style={{ width: "300px" }}>
                                                            71,234 GBP
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1} >
                                                            1,170,306
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1} >
                                                            202.63 GBP
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1} >
                                                            0.28%
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1} >
                                                            Awaiting Funds
                                                        </td>
                                                        <td className="sorting" rowSpan={1} colSpan={1}>
                                                            Jacon Arbury
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClientDeals