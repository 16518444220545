import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="xl"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Trade Beneficiaries : null (SP235643654)
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* Table */}
                <div className="row">
                    <div className="col-md-12">
                        <div className="card mb-4">
                            {/* <!-- Account --> */}
                            <div className="card-body">
                                <div className="card-datatable table-responsive ps-3">
                                    <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                        <table className="datatables-customers hm-table table border-top dataTable no-footer dtr-column hm-table-color" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info">
                                            <thead className="table-head-bg">
                                                <tr>
                                                    <th className="sorting" rowSpan={1} colSpan={1} style={{ width: "20px" }}>No</th>
                                                    <th className="sorting" rowSpan={1} colSpan={1}>Ticket</th>
                                                    <th className="sorting" rowSpan={1} colSpan={1} style={{ width: "250px" }}>Payment Amount</th>
                                                    <th className="sorting" rowSpan={1} colSpan={1}>Beneficiaries</th>
                                                    <th className="sorting" rowSpan={1} colSpan={1}>Country</th>
                                                    <th className="sorting" rowSpan={1} colSpan={1}>IBAN / Acount No</th>
                                                    <th className="sorting" rowSpan={1} colSpan={1}>BIC / Swift</th>
                                                    <th className="sorting" rowSpan={1} colSpan={1}>Sort Code</th>
                                                    <th className="sorting" rowSpan={1} colSpan={1}>Payment Refrence</th>
                                                    <th className="sorting" rowSpan={1} colSpan={1}>Remittance Mode</th>
                                                    <th className="sorting" rowSpan={1} colSpan={1}>Entry Date</th>
                                                    <th className="sorting" rowSpan={1} colSpan={1}>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                            </tbody>
                                        </table>
                                        <div className="row mx-2">
                                            <div className="col-sm-12 col-md-12">
                                                <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                                                    Showing 0 to 0 of 0 entries
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className='bg-danger border-0' onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}
export default MyVerticallyCenteredModal;
