import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Register from "./components/auth/register";
import Login from "./components/auth/login";
import Dashboard from "./components/Dashboard/Home/dashboard";
import Clients from "./components/Dashboard/Clients/ClientDetails/clientsDetails";
import ManageClients from "./components/Dashboard/Clients/manageClients";
import Deals from "./components/Dashboard/Deals/deals";
import Spot from "./components/Dashboard/Clients/ClientDetails/CustomerUserTrade";
import Trades from "./components/Dashboard/Clients/ClientDetails/spotTrades";
import DealOverview from "./components/Dashboard/Deals/deals-overview";
import AssignBeneficiaries from "./components/Dashboard/Deals/assign-beneficiaries";
import UnpaidSpotDeals from "./components/Dashboard/Deals/unpaid-spot-deals";
import Compliance from "./components/Dashboard/compliance/compliance";
import DrawdownRevenue from "./components/Dashboard/Reports/Drawdown";
import { Toaster } from "react-hot-toast";
import Profile from "./components/Dashboard/Accounts/Profile";


const App = () => {
  return (
    <>
    <Toaster />
    
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/account" element={<Profile/>}/>
          <Route path="/clients" element={<Clients />} />
          <Route path="/manage-clients" element={<ManageClients/>}/>
          <Route path="/deals" element={<Deals />} />
          <Route path="/deals/deal-overview" element={<DealOverview />} />
          <Route path="/clients/customer-user-trade" element={<Spot />} />
          <Route path="/clients/spot-trades" element={<Trades />} />
          <Route path="/deals/unpaid-spot-deals" element={<UnpaidSpotDeals/>}/>
          <Route path="/deals/assign-beneficiaries" element={<AssignBeneficiaries/>}/>
          <Route path="/compliance/tps-verification" element={<Compliance/>}/>
          <Route path="/reports/drawdown-revenue" element={<DrawdownRevenue/>}/>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
