import React from 'react'

const ApplicationCard = () => {
    return (
        <>
            <div className="card h-100">
                <div className="card-header">
                    <div className="d-flex justify-content-between mb-3">
                        <h4 className="card-title mb-0">Applications</h4>
                        <span className="text-muted">
                            February 2024
                        </span>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row gy-3">
                        <div className="col-sm-12 col-lg-4">
                            <div className="d-flex align-items-center">
                                <div className="badge rounded-pill bg-label-primary me-3 p-2">
                                    <i className="ti ti-chart-pie-2 ti-sm"></i>
                                </div>
                                <div className="card-info">
                                    <h5 className="mb-0">0</h5>
                                    <span>Completed Applications</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <div className="d-flex align-items-center">
                                <div className="badge rounded-pill bg-label-info me-3 p-2">
                                    <i className="ti ti-users ti-sm"></i>
                                </div>
                                <div className="card-info">
                                    <h5 className="mb-0">0</h5>
                                    <span>New Applications </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <div className="d-flex align-items-center">
                                <div className="badge rounded-pill bg-label-warning me-3 p-2">
                                    <i className="ti ti-clock ti-sm"></i>
                                </div>
                                <div className="card-info">
                                    <h5 className="mb-0">0</h5>
                                    <span>Pending Applications</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ApplicationCard