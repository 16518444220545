import React from 'react'
import { Link } from 'react-router-dom'
import user from "../../../../assets/img/illustrations/card-advance-sale.png";


const WelcomeCard = () => {
    return (
        <>
            <div className="card">
                <div className="d-flex align-items-end row">
                    <div className="col-7">
                        <div className="card-body text-nowrap">
                            <h4 className="card-title mb-0">
                                Welcome Back! 🎉
                            </h4>
                            <p className="mb-2">Sales of the month</p>
                            <h4 className="text-primary mb-1">48.9k</h4>
                            <Link to="" className="btn btn-primary">
                                View Sales
                            </Link>
                        </div>
                    </div>
                    <div className="col-5 text-center text-sm-left">
                        <div className="card-body pb-0 px-0 px-md-2">
                            <img src={user} height="140" alt="view sales" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WelcomeCard