import React, { useState } from "react";
import { Link } from "react-router-dom";

const DropdownList = ({
  className,
  icon,
  data1,
  data2,
  data3,
  data4,
  data5,
  title,
  link1,
  link2,
  link3,
  link4,
  link5,
  titleClass
}) => {
  const [isReportOpen, setIsReportOpen] = useState(false);

  const toggleReportDropdown = () => {
    setIsReportOpen(!isReportOpen);
  };

  return (
    <>
      <li className={className}>
        <div className="menu-link d-flex justify-content-between" onClick={toggleReportDropdown}>
          <div className="d-flex">{icon}
            <div className={titleClass} data-i18n={title}>{title}</div>
          </div>
          <div>
            <i className={`ti ${isReportOpen ? "ti-chevron-up" : "ti-chevron-down"}`}></i>
          </div>
        </div>
        {isReportOpen && (
          <ul className="submenu">
            <li className="menu-list">
              <Link to={link1}>{data1}</Link>
            </li>
            {data2 && data3 && (
              <>
                <li className="menu-list">
                  <Link to={link2}>{data2}</Link>
                </li>
                <li className="menu-list">
                  <Link to={link3}>{data3}</Link>
                </li>
                {data4 && data5 && (
                  <>
                    <li className="menu-list">
                      <Link to={link4}>{data4}</Link>
                    </li>
                    <li className="menu-list">
                      <Link to={link5}>{data5}</Link>
                    </li>
                  </>
                )}
              </>
            )}
          </ul>
        )}
      </li>
    </>
  );
};

export default DropdownList;
