import React from "react";
import Aside from "../../Layouts/sidebar/aside";
import Navbar from "../../Layouts/navbar";
import Footer from "../../Layouts/footer";
import { Link } from "react-router-dom";

export default function Trades() {


  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Aside />
          <div className="layout-page">
            <Navbar />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h3 className="mb-2 ps-3">Spot Trades of : Sandy Donaldson (GBR4413560)</h3>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card mb-4">
                      <div className="card-body">
                        <form id="formAccountSettings" >
                          <div className="row">
                            <div className="col-lg-9">
                              <div className="row">

                                <div className="mb-3 col-lg-3">
                                  <label htmlFor="Reference" className="form-label">Reference No</label>
                                  <input className="form-control" type="text" id="Reference" name="Reference" />
                                </div>

                                <div className="mb-3 col-lg-3">
                                  <label className="form-label">Trade From Date</label>
                                  <div className="input-group input-group-merge">
                                    <input type="date" className="form-control" />
                                  </div>
                                </div>

                                <div className="mb-3 col-lg-3">
                                  <label className="form-label">Trace to Date</label>
                                  <input type="date" className="form-control" />
                                </div>

                                <div className="mb-3 col-lg-3 d-flex align-items-end">
                                  <button type="submit" className="btn btn-primary me-2">Search</button>
                                  <button type="reset" className="btn btn-label-secondary">Reset</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="card-datatable table-responsive ps-3">
                          <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                            <table className="datatables-customers hm-table table border-top dataTable no-footer dtr-column hm-table-color" id="DataTables_Table_0" >
                              <thead className="table-head-bg" >
                                <tr>
                                  <th className="control sorting_disabled dtr-hidden" rowSpan={1} colSpan={1} style={{ width: "20px" }} >No</th>
                                  <th className="sorting sorting_desc" >
                                    Trade Ticket
                                  </th>
                                  <th className="sorting">
                                    Trade date
                                  </th>
                                  <th className="sorting">
                                    Value Date
                                  </th>
                                  <th className="text-nowrap sorting"  >
                                    Reference No
                                  </th>
                                  <th className="sorting" >
                                    Beneficiaries
                                  </th>
                                  <th className="sorting"  >
                                    Buy Amount
                                  </th>
                                  <th className="sorting"  >
                                    Sell Amount
                                  </th>
                                  <th className="sorting"  >
                                    Exchange Rate
                                  </th>
                                  <th className="sorting"  >
                                    Status
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* first Row */}
                                <tr>
                                  <td className="control sorting_disabled dtr-hidden" >1</td>
                                  <td className="sorting sorting_desc" ><i className="menu-icon tf-icons ti ti-files"></i></td>
                                  <td className="sorting">09-02-2024</td>
                                  <td className="sorting">09-02-2024</td>
                                  <td className="text-nowrap sorting"  ><Link to="">SP 240240 </Link></td>
                                  <td className="sorting"  ><Link to="">1</Link></td>
                                  <td className="sorting"  >83,712.00 EUR</td>
                                  <td className="sorting"  >1,167.00</td>
                                  <td className="sorting"  >71,250.00</td>
                                  <td className="sorting"  >Awaiting Funds</td>
                                </tr>

                                {/* second row */}
                                <tr>
                                  <td className="control sorting_disabled dtr-hidden" >2</td>
                                  <td className="sorting sorting_desc" > <i className="menu-icon tf-icons ti ti-files"></i></td>
                                  <td className="sorting">09-02-2024</td>
                                  <td className="sorting">09-02-2024</td>
                                  <td className="text-nowrap sorting"  ><Link to="">SP 240240 </Link></td>
                                  <td className="sorting"  ><Link to="">1</Link></td>
                                  <td className="sorting"  >83,712.00 EUR</td>
                                  <td className="sorting"  >1,167.00</td>
                                  <td className="sorting">71,250.00</td>
                                  <td className="sorting"  >Awaiting Funds</td>
                                </tr>

                                {/* Third row */}
                                <tr>
                                  <td className="control sorting_disabled dtr-hidden" >3</td>
                                  <td className="sorting sorting_desc" ><i className="menu-icon tf-icons ti ti-files"></i></td>
                                  <td className="sorting">09-02-2024</td>
                                  <td className="sorting">09-02-2024</td>
                                  <td className="text-nowrap sorting"  ><Link to="">SP 240240 </Link></td>
                                  <td className="sorting"  ><Link to="">1</Link></td>
                                  <td className="sorting"  >83,712.00 EUR</td>
                                  <td className="sorting"  >1,167.00</td>
                                  <td className="sorting"  >71,250.00</td>
                                  <td className="sorting"  >Awaiting Funds</td>
                                </tr>
                              </tbody>
                            </table>
                            
                            <div className="row mx-2">
                              <div className="col-sm-12 col-lg-12">
                                <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                                  <ul className="pagination">
                                    <li className="paginate_button page-item previous disabled" id="DataTables_Table_0_previous">
                                      <Link to={"#"} aria-controls="DataTables_Table_0" aria-disabled="true" role="link" data-dt-idx="previous" tabIndex={0} className="page-link">
                                        Previous
                                      </Link>
                                    </li>
                                    <li className="paginate_button page-item previous bg-warning  mx-1" id="DataTables_Table_0_previous">
                                      <Link to={"#"} aria-controls="DataTables_Table_0" aria-disabled="true" role="link" data-dt-idx="previous" tabIndex={0} className="page-link text-white">
                                        1
                                      </Link>
                                    </li>
                                    <li className="paginate_button page-item next disabled" id="DataTables_Table_0_next" >
                                      <Link to={""} aria-controls="DataTables_Table_0" aria-disabled="true" role="link" data-dt-idx="next" tabIndex={0} className="page-link">
                                        Next
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>
    </>
  )
}