import React from 'react';
import Aside from "../Layouts/sidebar/aside";
import Navbar from "../Layouts/navbar";
import Footer from "../Layouts/footer";
import { Link } from "react-router-dom";


const AssignBeneficiaries = () => {
  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Aside />
          <div className="layout-page">
            <Navbar />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h3 className="mb-2 ps-3">Assign Beneficiaries</h3>
                <div className="row">
                  <div className="col-lg-12">

                    <div className="card mb-4">
                      {/* <!-- Account --> */}

                      <div className="card-body">
                        <form id="formAccountSettings" >
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="row">
                                <div className="mb-3 col-lg-3">
                                  <label htmlFor="customerName" className="form-label">Customer Name</label>
                                  <input className="form-control" type="text" id="customerName" name="customerName" placeholder="John" />
                                </div>

                                <div className="mb-3 col-lg-3">
                                  <label className="form-label" htmlFor="clientNumber">Client Code</label>
                                  <div className="input-group input-group-merge">
                                    <input type="text" id="clientNumber" name="clientNumber" className="form-control" placeholder="GBR 555 0111" />
                                  </div>
                                </div>

                                <div className="mb-3 col-lg-3">
                                  <label htmlFor="dealNo" className="form-label">Deal No</label>
                                  <input type="text" className="form-control" id="deal No" name="dealNo" placeholder="SP1234587" />
                                </div>

                                <div className="mb-3 col-lg-3">
                                  <label htmlFor="status" className="form-label">Trade From Date</label>
                                  <input type="date" className="form-control" id="status" name="status" />
                                </div>

                                <div className="mb-3 col-lg-3">
                                  <label htmlFor="status" className="form-label">Trade To Date</label>
                                  <input type="date" className="form-control" id="status" name="status" />
                                </div>

                                <div className="mb-3 col-lg-3">
                                  <label htmlFor="buyCurrency" className="form-label">Select Sell Currency</label>
                                  <select id="buyCurrency" className="select2 form-select">
                                    <option value="">Select</option>
                                    <option value="usd">USD</option>
                                    <option value="euro">Euro</option>
                                    <option value="pound">Pound</option>
                                    <option value="bitcoin">Bitcoin</option>
                                  </select>
                                </div>

                                <div className="mb-3 col-lg-3">
                                  <label htmlFor="buyCurrency" className="form-label">Select Buy Currency</label>
                                  <select id="buyCurrency" className="select2 form-select">
                                    <option value="">Select</option>
                                    <option value="usd">USD</option>
                                    <option value="euro">Euro</option>
                                    <option value="pound">Pound</option>
                                    <option value="bitcoin">Bitcoin</option>
                                  </select>
                                </div>

                                <div className="mb-3 col-lg-3 d-flex align-items-end pb-2">
                                  <div className="form-check">
                                    <input type="checkbox" className="form-check-input" />
                                    <label className="form-check-label" htmlFor="defaultCheck3"> Search Group Clients </label>
                                  </div>
                                </div>

                              </div>
                            </div>

                          </div>



                          <div className="row mt-2">
                            <div className="col-lg-12 d-flex align-items-center">
                              <button type="submit" className="btn btn-primary me-2">Save changes</button>
                              <button type="reset" className="btn btn-label-secondary">Cancel</button>
                            </div>
                          </div>

                        </form>
                      </div>

                      {/* <!-- /Account --> */}
                    </div>

                    <div className="card">

                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="card mb-4">
                      {/* <!-- Account --> */}

                      <div className="card-body">
                        <div className="card-datatable table-responsive ps-3">
                          <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">

                            <table className="datatables-customers hm-table table border-top dataTable no-footer dtr-column hm-table-color" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info">
                              <thead className="table-head-bg" >
                                <tr>
                                  <th className="control sorting_disabled dtr-hidden"  >No</th>
                                  <th className="sorting sorting_desc"  > Customer</th>
                                  <th className="text-nowrap sorting"  > Deal No</th>
                                  <th className="sorting"  > Client Code</th>
                                  <th className="sorting"  > Trade date</th>
                                  <th className="sorting"  > Value Date</th>
                                  <th className="sorting"  > Beneficiaries</th>
                                  <th className="sorting"  >Buy Amount</th>
                                  <th className="sorting"  > Client Rate</th>
                                  <th className="sorting"  > Sell Amount</th>
                                  <th className="sorting"  > Remittance Fee</th>
                                  <th className="sorting"  > Payable Amount</th>
                                  <th className="sorting"  > Boooked Rate</th>
                                  <th className="sorting"  > Revenue</th>
                                  <th className="sorting"  > Fx Margin</th>
                                  <th className="sorting"  > Status</th>
                                  <th className="sorting"  > Account Manager</th>
                                </tr>
                              </thead>

                              <tbody>
                                {/* first Row */}
                                <tr>
                                  <td className="control sorting_disabled dtr-hidden"  >1</td>
                                  <td className="sorting sorting_desc" > Emmacy Limited</td>
                                  <td className="text-nowrap sorting" ><Link to="/deals/deal-overview">SP 240240 </Link></td>
                                  <td className="sorting"  ><Link to="" >GBP 28563210</Link></td>
                                  <td className="sorting"  > 09-02-2024</td>
                                  <td className="sorting"  > 09-02-2024</td>
                                  <td className="sorting"  ><Link to="" >1</Link></td>
                                  <td className="sorting"  >83,712.00 EUR</td>
                                  <td className="sorting"  > 1,167.00</td>
                                  <td className="sorting"  > 71,250.00</td>
                                  <td className="sorting"  > 0.00</td>
                                  <td className="sorting"  > 71,234 GBP</td>
                                  <td className="sorting"  > 1,170,306</td>
                                  <td className="sorting"  > 202.63 GBP</td>
                                  <td className="sorting"  > 0.28%</td>
                                  <td className="sorting"  > Awaiting Funds</td>
                                  <td className="sorting"  > Jacon Arbury</td>
                                </tr>

                                {/* second Row */}
                                <tr>
                                  <td className="control sorting_disabled dtr-hidden"  >2</td>
                                  <td className="sorting sorting_desc"  > Iconic State</td>
                                  <td className="text-nowrap sorting"  > <Link to="/deals/deal-overview">SP 240240 </Link></td>
                                  <td className="sorting" > <Link to="" >GBP 28563210</Link></td>
                                  <td className="sorting"  > 09-02-2024</td>
                                  <td className="sorting"  > 09-02-2024</td>
                                  <td className="sorting"  > <Link to="" >1</Link></td>
                                  <td className="sorting"  > 83,712.00 EUR</td>
                                  <td className="sorting"  > 1,167.00</td>
                                  <td className="sorting"  > 71,250.00</td>
                                  <td className="sorting"  > 0.00</td>
                                  <td className="sorting"  > 71,234 GBP</td>
                                  <td className="sorting"  > 1,170,306</td>
                                  <td className="sorting"  > 202.63 GBP</td>
                                  <td className="sorting"  > 0.28%</td>
                                  <td className="sorting"  > Awaiting Funds</td>
                                  <td className="sorting"  > Jacon Arbury</td>
                                </tr>

                                {/* Third Row */}
                                <tr>
                                  <td className="control sorting_disabled dtr-hidden"  >3</td>
                                  <td className="sorting sorting_desc"  > Kong Climbing LTD</td>
                                  <td className="text-nowrap sorting"  > <Link to="/deals/deal-overview">SP 240240 </Link></td>
                                  <td className="sorting" > <Link to="" >GBP 28563210</Link></td>
                                  <td className="sorting"  > 09-02-2024</td>
                                  <td className="sorting"  > 09-02-2024</td>
                                  <td className="sorting"  > <Link to="" >1</Link></td>
                                  <td className="sorting"  > 83,712.00 EUR</td>
                                  <td className="sorting"  > 1,167.00</td>
                                  <td className="sorting"  > 71,250.00</td>
                                  <td className="sorting"  > 0.00</td>
                                  <td className="sorting"  > 71,234 GBP</td>
                                  <td className="sorting"  > 1,170,306</td>
                                  <td className="sorting"  > 202.63 GBP</td>
                                  <td className="sorting"  > 0.28%</td>
                                  <td className="sorting"  > Awaiting Funds</td>
                                  <td className="sorting"  > Jacon Arbury</td>
                                </tr>

                                {/* Fourth Row */}
                                <tr>
                                  <td className="control sorting_disabled dtr-hidden"  >4</td>
                                  <td className="sorting sorting_desc"  >Media 1 Production LTD</td>
                                  <td className="text-nowrap sorting"  ><Link to="/deals/deal-overview">SP 240240 </Link></td>
                                  <td className="sorting"  ><Link to="" >GBP 28563210</Link></td>
                                  <td className="sorting"  >09-02-2024</td>
                                  <td className="sorting"  >09-02-2024</td>
                                  <td className="sorting"  ><Link to="" >1</Link></td>
                                  <td className="sorting"  >83,712.00 EUR</td>
                                  <td className="sorting"  >1,167.00</td>
                                  <td className="sorting"  >71,250.00</td>
                                  <td className="sorting"  >0.00</td>
                                  <td className="sorting"  >71,234 GBP</td>
                                  <td className="sorting"  >1,170,306</td>
                                  <td className="sorting"  >202.63 GBP</td>
                                  <td className="sorting"  >0.28%</td>
                                  <td className="sorting"  >Awaiting Funds</td>
                                  <td className="sorting"  >Jacon Arbury</td>
                                </tr>

                              </tbody>
                            </table>


                            <div className="row mx-2">

                              <div className="col-sm-12 col-lg-6">
                                <div className="dataTables_info" id="DataTables_Table_0_info" >
                                  Showing 0 to 0 of 0 entries
                                </div>
                              </div>

                              <div className="col-sm-12 col-lg-6">
                                <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                                  <ul className="pagination">

                                    <li className="paginate_button page-item previous disabled" id="DataTables_Table_0_previous">
                                      <Link data-dt-idx="previous" className="page-link">
                                        Previous
                                      </Link>
                                    </li>

                                    <li className="paginate_button page-item next disabled" id="DataTables_Table_0_next" >
                                      <Link data-dt-idx="next" className="page-link">
                                        Next
                                      </Link>
                                    </li>

                                  </ul>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AssignBeneficiaries;