import React from "react";
import Aside from "../Layouts/sidebar/aside";
import Navbar from "../Layouts/navbar";
import Footer from "../Layouts/footer";
import Check from '../../../assets/img/icons/icons8-check-mark-20.png';
import { Link } from "react-router-dom";

const DealOverview = () => {
    return (
        <>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <Aside />
                    <div className="layout-page">
                        <Navbar />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="d-flex justify-content-between">
                                    <h4 className="pt-3 mb-2">
                                        Deal Overview - ABBEY LABELS LIMITED <span className="text-muted fw-light">(SP24809995)</span>
                                    </h4>
                                    <Link to='/deals' className="btn btn-warning">
                                        Back
                                    </Link>
                                </div>
                                <div id="client-overview">
                                    <div className="row" >
                                        <div className="col-lg-6">
                                            <div className="card mt-2">
                                                <div className="account-details my-4 px-4">
                                                    <div className="d-flex justify-content-between">
                                                        <div> <h3 className="mb-3">Order Details</h3></div>
                                                        <div><i className="ti ti-mail ti-sm text-success"></i></div>
                                                    </div>
                                                    <div className="account-details-table ps-2 card-datatable table-responsive">
                                                        <table style={{ width: "100%;" }}>
                                                            <tr>
                                                                <td style={{ width: "280.172px;" }}><b>Order Status</b></td>
                                                                <td><small className="fw-medium">Fund received</small></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: "280.172px;" }}><b>Order Date</b></td>
                                                                <td><small className="fw-medium">07-FEB-2024 10:30:19</small></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: "280.172px;" }}><b>Value Date</b></td>
                                                                <td><small className="fw-medium">07-FEB-2024</small></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: "280.172px;" }}><b>You Bought</b></td>
                                                                <td><small className="fw-medium">11,717.00 EUR</small></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: "280.172px;" }}><b>Exchange Rate </b></td>
                                                                <td><small className="fw-medium">1,7171</small></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: "280.172px;" }}><b>You Sold</b></td>
                                                                <td><small className="fw-medium">10,000.00 GBP</small><i className="fas fa-edit text-warning"></i></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: "280.172px;" }}><b>Remittance Fee</b></td>
                                                                <td><small className="fw-medium">0.00 GBP</small></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: "280.172px;" }}><b>Discount</b></td>
                                                                <td><small className="fw-medium">0.00 GBP</small></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: "280.172px;" }}><b>Total Amount Payable </b></td>
                                                                <td><small className="fw-medium">10,000.00</small></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: "280.172px;" }}><b>Booked By</b></td>
                                                                <td><small className="fw-medium">Heather Brett</small></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: "280.172px;" }}><b>Revenue</b></td>
                                                                <td><small className="fw-medium">10.55 GBP</small></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: "280.172px;" }}><b>Booked Rate</b></td>
                                                                <td><small className="fw-medium">1.1729380</small></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="card mt-2 p-3 ">
                                                <div className="my-2 d-flex justify-content-between">
                                                    <h4 className="mb-2">
                                                        Your Payments
                                                    </h4>
                                                    <div className="dropdown">
                                                        <button type="button" className="btn btn-label-primary dropdown-toggle waves-effect" data-bs-toggle="dropdown" aria-expanded="false">
                                                            Action
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            <li>
                                                                <Link className="dropdown-item" to="">Cancel Deal</Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="card-datatable table-responsive mt-3">
                                                    <table style={{ width: "100%" }}>
                                                        <tr className="bg-primary">
                                                            <th className="border-1 p-2 text-white">Amount Received</th>
                                                            <th className="border-1 p-2 text-white">Received By</th>
                                                            <th className="border-1 p-2 text-white">Date Received</th>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-1 p-3">10,00.00 GBP</td>
                                                            <td className="border-1 p-3">Bank Transfer</td>
                                                            <td className="border-1 p-3">07-Feb-2024</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div className="d-flex text-center flex-column">
                                                    <p className="btn btn-label-primary waves-effect">Balance Due = 0.00 GBP</p>
                                                    <p className="btn btn-label-primary waves-effect">Available to Assign (Free funding) = 8,113.00  EUR (6,342.12 GBP)</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 mt-3">
                                            <div className="card">
                                                <div className="card-datatable table-responsive">
                                                    <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                                        <div className="card-header d-flex flex-wrap pb-2">
                                                            <div className="d-flex align-items-center">
                                                                <div id="DataTables_Table_0_filter d-flex align-items-center" className="dataTables_filter me-3 ps-0">
                                                                    <h5 className="m-0">Assigned Beneficiaries</h5>
                                                                </div>
                                                                <div className="dataTables_length" id="DataTables_Table_0_length">
                                                                    <label>
                                                                        <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="form-select">
                                                                            <option value="">Select Beneficiaries</option>
                                                                            <option value="10">10</option>
                                                                            <option value="20">20</option>
                                                                            <option value="50">50</option>
                                                                            <option value="70">70</option>
                                                                            <option value="100">100</option>
                                                                        </select>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-content-center justify-content-lg-end align-items-baseline">
                                                                <div className="dt-action-buttons d-flex justify-content-between flex-lg-row mb-3 mb-lg-0 ps-1 ms-1 align-items-baseline pt-0">
                                                                    <div className="me-3">
                                                                        <button className="btn btn-info waves-effect waves-light"> Keep Fund in wallet</button>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <p className="btn btn-label-primary waves-effect" >Remaining To Assign = 8,113.00 EUR</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <table className="datatables-category-list table border-top">
                                                            <thead>
                                                                <tr className="bg-primary">
                                                                    <th className="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all  text-white">Amount</th>
                                                                    <th className="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all  text-white">Settlement Amount</th>
                                                                    <th className="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all  text-white">Rate </th>
                                                                    <th className="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all  text-white">Fees </th>
                                                                    <th className="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all  text-white">Date</th>
                                                                    <th className="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all  text-white">Country</th>
                                                                    <th className="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all  text-white">Beneficiaries Name</th>
                                                                    <th className="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all  text-white">Payment Referance</th>
                                                                    <th className="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all  text-white">Status</th>
                                                                    <th className="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all  text-white">Action</th>
                                                                    <th className="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all  text-white">Emails</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all p-3">3,604.235 EUR </td>
                                                                    <td className="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all p-3">3,524.365 GBP</td>
                                                                    <td className="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all p-3">1,7171 </td>
                                                                    <td className="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all p-3">0.00 GBP</td>
                                                                    <td className="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all p-3">07-02-2024</td>
                                                                    <td className="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all p-3">Denmark</td>
                                                                    <td className="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all p-3"><img src={Check} alt="check"/> <span className="text-warning">VETAPHONE A/S</span></td>
                                                                    <td className="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all p-3">ABBEY LABELS LTD 50050</td>
                                                                    <td className="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all p-3"><span className="badge rounded-pill bg-label-success">Remitted</span></td>
                                                                    <td className="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all p-3"></td>
                                                                    <td className="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all p-3"><i className="ti ti-mail ti-sm text-success"></i></td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default DealOverview;