

function footer(){
    return(
        <>
            <footer className="content-footer footer bg-footer-theme">
                <div className="container-xxl">
                    <div className="footer-container d-flex align-items-center justify-content-between py-2 flex-md-row flex-column">
                        <div>
                            © Copywrite.
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
};
export default footer;