import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Aside from "../Layouts/sidebar/aside";
import Navbar from "../Layouts/navbar";
import Footer from "../Layouts/footer";
import Select from 'react-select'

export default function Deals() {

  useEffect(()=>{
    getDealsData();
  }, [])

  const getDealsData = async()=>{
    // const url = process.env.REACT_APP_SERVER_DOMAIN;
    // try {
    //   const response = await axios.get(`${url}/api/v1/deals?limit=100&offset=0`);
    //   console.log(response);
    // } catch (error) {
    //   console.log(error);
    // }
  }

  const [data, setData] = useState({
    customerName: "",
    clientNumber: "",
    dealNo: "",
    status: "",
    sellCurrency: "",
    buyCurrency: "",
    deal_type: "",
    created_date: "",
    valid_date: "",
  });

  const handleSelectChange = (selectedOptions) => {
    setData({ ...data, status: selectedOptions });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const url = process.env.REACT_APP_SERVER_DOMAIN
    // try {
    //   console.log(data);
    //   const response = await axios.post(`${url}/api/v1/deals`, data);
    //   console.log(response);
    //   toast.success(response.data.message);
    // } catch (error) {
    //   toast.error(error.response.data.message);
    // }
  }

  const Options = [
    { value: 'Balance Cancelled', label: 'Balance Cancelled' },
    { value: 'Cancelled', label: 'Cancelled' },
    { value: 'Completed', label: 'Completed' },
    { value: 'Compliance Query', label: 'Compliance Query' },
    { value: 'Deposit Paid', label: 'Deposit Paid' },
    { value: 'Fund Received', label: 'Fund Received' },
    { value: 'Deposit Partially Paid', label: 'Deposit Partially Paid' },
    { value: 'Fund Partially Received', label: 'Fund Partially Received' },
    { value: 'Awaiting Funds', label: 'Awaiting Funds' }
  ]
  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Aside />
          <div className="layout-page">
            <Navbar />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h3 className="mb-2 ps-3">All Deals</h3>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card mb-4">
                      <div className="card-body">
                        <form id="formAccountSettings" method="GET" onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-lg-9">
                              <div className="row">

                                <div className="mb-3 col-sm-12 col-md-6 col-lg-4 ">
                                  <label htmlFor="customerName" className="form-label">Customer Name</label>
                                  <input className="form-control" type="text" id="customerName" name="customerName" placeholder="John" value={data.email} onChange={handleChange} />
                                </div>

                                <div className="mb-3 col-sm-12 col-md-6 col-lg-4 ">
                                  <label className="form-label" htmlFor="clientNumber">Client Code</label>
                                  <div className="input-group input-group-merge">
                                    <input type="text" id="clientNumber" name="clientNumber" className="form-control" placeholder="GBR 555 0111" value={data.email} onChange={handleChange} />
                                  </div>
                                </div>

                                <div className="mb-3 col-sm-12 col-md-6 col-lg-4 ">
                                  <label htmlFor="dealNo" className="form-label">Deal No</label>
                                  <input type="text" className="form-control" id="deal No" name="dealNo" placeholder="SP1234587" value={data.dealNo} onChange={handleChange} />
                                </div>

                                <div className="mb-3 col-sm-12 col-md-6 col-lg-4 ">
                                  <label htmlFor="deal_type" className="form-label">Select Deal Type</label>
                                  <select id="deal_type" className="select2 form-select" name="deal_type" value={data.deal_type} onChange={handleChange}>
                                    <option value="">Select</option>
                                    <option value="final">Final</option>
                                  </select>
                                </div>

                                <div className="mb-3 col-sm-12 col-md-6 col-lg-4 ">
                                  <label className="form-label" htmlFor="sellCurrency">Select sell Currency</label>
                                  <select id="sellCurrency" className="select2 form-select" name="sellCurrency" value={data.sellCurrency} onChange={handleChange}>
                                    <option value="">Select</option>
                                    <option value="USD"   label="USD">USD</option>
                                    <option value="EUR" label="EUR">EUR</option>
                                    <option value="JPY" label="JPY">JPY</option>
                                    <option value="GBP" label="GBP">GBP</option>
                                    <option value="AED" label="AED">AED</option>
                                    <option value="AFN" label="AFN">AFN</option>
                                    <option value="PKR" label="PKR">PKR</option>
                                    <option value="RUB" label="RUB">RUB</option>
                                    <option value="ALL" label="ALL">ALL</option>
                                    <option value="AMD" label="AMD">AMD</option>
                                    <option value="ANG" label="ANG">ANG</option>
                                    <option value="AOA" label="AOA">AOA</option>
                                    <option value="ARS" label="ARS">ARS</option>
                                    <option value="AUD" label="AUD">AUD</option>
                                    <option value="AWG" label="AWG">AWG</option>
                                    <option value="AZN" label="AZN">AZN</option>
                                    <option value="BAM" label="BAM">BAM</option>
                                  </select>
                                </div>
                                <div className="mb-3 col-sm-12 col-md-6 col-lg-4 ">
                                  <label htmlFor="buyCurrency" className="form-label">Select Buy Currency</label>
                                    <select id="buyCurrency" className="select2 form-select" name="buyCurrency" value={data.buyCurrency} onChange={handleChange}>
                                    <option value="USD"   label="USD">USD</option>
                                    <option value="EUR" label="EUR">EUR</option>
                                    <option value="JPY" label="JPY">JPY</option>
                                    <option value="GBP" label="GBP">GBP</option>
                                    <option value="AED" label="AED">AED</option>
                                    <option value="AFN" label="AFN">AFN</option>
                                    <option value="PKR" label="PKR">PKR</option>
                                    <option value="RUB" label="RUB">RUB</option>
                                    <option value="ALL" label="ALL">ALL</option>
                                    <option value="AMD" label="AMD">AMD</option>
                                    <option value="ANG" label="ANG">ANG</option>
                                    <option value="AOA" label="AOA">AOA</option>
                                    <option value="ARS" label="ARS">ARS</option>
                                    <option value="AUD" label="AUD">AUD</option>
                                    <option value="AWG" label="AWG">AWG</option>
                                    <option value="AZN" label="AZN">AZN</option>
                                    <option value="BAM" label="BAM">BAM</option>
                                  </select>
                                </div>

                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                                  <label className="form-label" htmlFor="status">Select Status</label>
                                  <Select
                                    isMulti
                                    options={Options}
                                    name="status"
                                    id="status"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    value={data.status}
                                    onChange={handleSelectChange} // Use handleSelectChange function here
                                  />
                                </div>

                                <div className="mb-3 col-sm-12 col-md-6 col-lg-4  d-flex align-items-end pb-2">
                                  <div className="form-check">
                                    <input type="checkbox" name="" className="form-check-input" />
                                    <label className="form-check-label" htmlFor="defaultCheck3"> Search Group Clients </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-3">
                              <div className="row">
                                <div className="col-12">
                                  <div >
                                    <label htmlFor="created_date" className="form-label">From</label>
                                    <input type="date" className="form-control" id="status" name="created_date"
                                      value={data.created_date}
                                      onChange={handleChange} />
                                  </div>
                                  <div className="mt-3">
                                    <label htmlFor="valid_date" className="form-label">To</label>
                                    <input type="date" className="form-control" id="status" name="valid_date" value={data.valid_date} onChange={handleChange} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-lg-12 d-flex align-items-center">
                              <button type="submit" className="btn btn-primary me-2">Save changes</button>
                              <button type="reset" className="btn btn-label-secondary">Cancel</button>
                            </div>
                          </div>

                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                {/* // */}
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="card-datatable table-responsive ps-3">
                          <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                            <table className="datatables-customers hm-table table border-top dataTable no-footer dtr-column hm-table-color" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info">
                              <thead className="table-head-bg" >
                                <tr>
                                  <th className="control sorting_disabled dtr-hidden" >No</th>
                                  <th className="sorting sorting_desc" >Customer</th>
                                  <th className="text-nowrap sorting"  >Deal No</th>
                                  <th className="sorting" >Client Code</th>
                                  <th className="sorting" >Trade date</th>
                                  <th className="sorting" >Value Date</th>
                                  <th className="sorting" >Beneficiaries</th>
                                  <th className="sorting" >Buy Amount</th>
                                  <th className="sorting" >Client Rate</th>
                                  <th className="sorting" >Sell Amount</th>
                                  <th className="sorting" >Remittance Fee</th>
                                  <th className="sorting" >Payable Amount</th>
                                  <th className="sorting" >Boooked Rate</th>
                                  <th className="sorting" >Revenue</th>
                                  <th className="sorting" >Fx Margin</th>
                                  <th className="sorting" >Status</th>
                                  <th className="sorting" >Account Manager</th>
                                </tr>
                              </thead>

                              <tbody>
                                {/* first Row */}
                                <tr>
                                  <td className="control sorting_disabled dtr-hidden" >1</td>
                                  <td className="sorting sorting_desc" >Emmacy Limited</td>
                                  <td className="text-nowrap sorting"  ><Link to="/deals/deal-overview">SP 240240 </Link></td>
                                  <td className="sorting" ><Link to="" >GBP 28563210</Link></td>
                                  <td className="sorting" >09-02-2024</td>
                                  <td className="sorting" >09-02-2024</td>
                                  <td className="sorting" ><Link to="" >1</Link></td>
                                  <td className="sorting" >83,712.00 EUR</td>
                                  <td className="sorting" >1,167.00</td>
                                  <td className="sorting" >71,250.00</td>
                                  <td className="sorting" >0.00</td>
                                  <td className="sorting" >71,234 GBP</td>
                                  <td className="sorting" >1,170,306</td>
                                  <td className="sorting" >202.63 GBP</td>
                                  <td className="sorting" >0.28%</td>
                                  <td className="sorting" >Awaiting Funds</td>
                                  <td className="sorting" >Jacon Arbury</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>
    </>
  )
}