import React from "react";
import Aside from "../Layouts/sidebar/aside";
import Navbar from "../Layouts/navbar";
import Footer from "../Layouts/footer";
import Select from 'react-select'
import { Link } from "react-router-dom";


export default function Compliance() {
  const Options = [
    { value: 'Balance Cancelled', label: 'Balance Cancelled' },
    { value: 'Cancelled', label: 'Cancelled' },
    { value: 'Completed', label: 'Completed' },
    { value: 'Fund Received', label: 'Fund Received' },
    { value: 'Awaiting Funds', label: 'Awaiting Funds' }
  ]

  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Aside />
          <div className="layout-page">
            <Navbar />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h3 className="mb-2 ps-3">TPS Verification</h3>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card mb-4">
                      <div className="card-body">
                        <form id="formAccountSettings" >
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="row">
                                <div className="mb-3 col-lg-3">
                                  <label htmlFor="Reference" className="form-label">Telephone</label>
                                  <input className="form-control" type="text" id="Reference" name="Reference" />
                                </div>

                                <div className="col-lg-3 mb-3">
                                  <label className="form-label">Select Status</label>
                                  <Select isMulti options={Options} className="basic-multi-select" classNamePrefix="select" />
                                </div>

                                <div className="mb-3 col-lg-3">
                                  <label className="form-label">Trade From Date</label>
                                  <div className="input-group input-group-merge">
                                    <input type="date" className="form-control" />
                                  </div>
                                </div>

                                <div className="mb-3 col-lg-3">
                                  <label className="form-label">Trace to Date</label>
                                  <input type="date" className="form-control" />
                                </div>

                                <div className="mb-3 col-lg-3 d-flex align-items-end">
                                  <button type="submit" className="btn btn-primary me-2">Search</button>
                                  <button type="reset" className="btn btn-label-secondary">Reset</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="card-datatable table-responsive ps-3">
                          <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                            <table className="datatables-customers hm-table table border-top dataTable no-footer dtr-column hm-table-color" id="DataTables_Table_0" >
                              <thead className="table-head-bg" >
                                <tr>
                                  <th className="control sorting_disabled dtr-hidden" style={{ width: "20px" }} >No</th>
                                  <th className="sorting sorting_desc" >Telephone</th>
                                  <th className="sorting">Status  </th>
                                  <th className="sorting">Check Time</th>
                                  <th className="text-nowrap sorting"  >User</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr></tr>
                              </tbody>
                            </table>

                            <div className="row mx-2">
                              <div className="col-sm-12 col-lg-6">
                                <div>No Records Found</div>
                              </div>
                              <div className="col-sm-12 col-lg-6">
                                <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                                  <ul className="pagination">

                                    <li className="paginate_button page-item previous disabled" id="DataTables_Table_0_previous">
                                      <Link to={""} aria-controls="DataTables_Table_0" aria-disabled="true" role="link" data-dt-idx="previous" tabIndex={0} className="page-link">
                                        Previous
                                      </Link>
                                    </li>

                                    <li className="paginate_button page-item previous bg-warning text-white mx-1" id="DataTables_Table_0_previous">
                                      <Link to={""} aria-controls="DataTables_Table_0" aria-disabled="true" role="link" data-dt-idx="previous" tabIndex={0} className="page-link">
                                        1
                                      </Link>
                                    </li>

                                    <li className="paginate_button page-item next disabled" id="DataTables_Table_0_next" >
                                      <Link to={""} aria-controls="DataTables_Table_0" aria-disabled="true" role="link" data-dt-idx="next" tabIndex={0} className="page-link">
                                        Next
                                      </Link>
                                    </li>

                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>
    </>
  )
}