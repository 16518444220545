import { Link , useNavigate } from 'react-router-dom';
import { useState } from 'react';
import toast from 'react-hot-toast';
import axios from 'axios';
import '../../assets/css/demo.css';
import '../../assets/vendor/css/core.css';
import '../../assets/vendor/css/theme-default.css';
import '../../assets/vendor/css/pages/page-auth.css';

function Login() {

  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    // const url = process.env.REACT_APP_SERVER_DOMAIN
    // try {
    //   if(data.email === ""){
    //     return toast.error("Please enter a valid email address!");
    //   }
    //   if(data.password === ""){
    //     return toast.error("Enter password!");
    //   }
    //   const response = await axios.post(`${url}/api/v1/auth/login`, data);
    //   toast.success(response.data.message);
    //   navigate("/dashboard");
    // } catch (error) {
    //   console.log(error);
    //     toast.error(error.response.data.message)
    // }

    if(data.email === "" || data.password === ""){
      if(data.email === ""){
        toast.error("Email can't be empty!")
      }else if(data.password === ""){
        toast.error("Password can't be empty")
      }
    }else{
      toast.success("Logged in successfully")
      navigate('/dashboard');
    }

  }
 
  return (
    <div className="App">
       <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner py-4">
     
          <div className="card">
            <div className="card-body">
       
              <div className="app-brand justify-content-center mb-4 mt-2">
                <Link to="" className="app-brand-link gap-2">
                  <span className="app-brand-logo demo">
                    <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd"clipRule="evenodd"d="M0.00172773 0V6.85398C0.00172773 6.85398 -0.133178 9.01207 1.98092 10.8388L13.6912 21.9964L19.7809 21.9181L18.8042 9.88248L16.4951 7.17289L9.23799 0H0.00172773Z" fill="#7367F0" />
                      <path opacity="0.06"fillRule="evenodd"clipRule="evenodd"d="M7.69824 16.4364L12.5199 3.23696L16.5541 7.25596L7.69824 16.4364Z"fill="#161616" />
                      <path opacity="0.06"fillRule="evenodd"clipRule="evenodd"d="M8.07751 15.9175L13.9419 4.63989L16.5849 7.28475L8.07751 15.9175Z"fill="#161616" />
                      <path fillRule="evenodd" clipRule="evenodd"d="M7.77295 16.3566L23.6563 0H32V6.88383C32 6.88383 31.8262 9.17836 30.6591 10.4057L19.7824 22H13.6938L7.77295 16.3566Z"fill="#7367F0" />
                    </svg>
                  </span>
                  <span className="app-brand-text demo text-body fw-bold ms-1">EQUALS Money</span>
                </Link>
              </div>
      
              <h4 className="mb-1 pt-2">Welcome  👋</h4>
              <p className="mb-4">Please sign-in to your account and start the adventure</p>

              <form id="formAuthentication" className="mb-3" action="" method="GET" onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email or Username</label>
                  <input type="text" className="form-control" id="email" name="email" placeholder="Enter your email"
                    value={data.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3 form-password-toggle">
                  <div className="d-flex justify-content-between">
                    <label className="form-label" htmlFor="password">Password</label>
                    <Link to="auth-forgot-password-basic.html">
                      <small>Forgot Password?</small> 
                    </Link>
                  </div>
                  <div className="input-group input-group-merge">
                  <input
                      type={passwordVisible ? 'text' : 'password'}
                      id="password"
                      className="form-control"
                      name="password"
                      placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                      aria-describedby="password"
                      value={data.password}
                      onChange={handleChange}
                    />
                    <span className="input-group-text cursor-pointer" onClick={togglePasswordVisibility}><i className={`ti ${passwordVisible ? 'ti-eye' : 'ti-eye-off'}`}></i></span>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="remember-me" />
                    <label className="form-check-label" htmlFor="remember-me"> Remember Me </label>
                  </div>
                </div>
                <div className="mb-3">
                  <button className="btn btn-primary d-grid w-100">Sign in</button>
                </div>
              </form>

              <p className="text-center">
                <span>New on our platform?</span>
                <Link to={'/register'}> Create an account</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
