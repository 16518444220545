import React from 'react'

const Feilds = ({
    from,
    fromID,
    to,
    toID,
    margin,
    marginID
}) => {
    return (
        <>
            <div className="row my-2 justify-content-center">
                <div className="mb-3 col-lg-4 d-flex text-center align-items-center ">
                    <label htmlFor="fromGBP" className="form-label" style={{ width: "300px" }}>{from} :</label>
                    <input className="form-control bg-light" type="number" id={fromID} name="fromGBP" placeholder="Amount Value" />
                </div>

                <div className="mb-3 col-lg-4 d-flex text-center align-items-center">
                    <label htmlFor="fromGBP" className="form-label" style={{ width: "300px" }}>{to} :</label>
                    <input className="form-control" type="number" id={toID} name="fromGBP" placeholder="Amount Value" />
                </div>

                <div className="mb-3 col-lg-4 d-flex text-center align-items-center">
                    <label htmlFor="fromGBP" className="form-label" style={{ width: "300px" }}>{margin} :</label>
                    <input className="form-control" type="number" id={marginID} name="fromGBP" placeholder="%" />
                </div>
            </div>
        </>
    )
}

export default Feilds