import React, { useState } from "react";
import Aside from "../../Layouts/sidebar/aside";
import Navbar from "../../Layouts/navbar";
import Footer from "../../Layouts/footer";
import Check from '../../../../assets/img/icons/icons8-check-mark-20.png';
import Table from "./Table/table";
import ClientDeals from "../ClientDeals/clientDeals";
import ClientBeneficiaries from "../Beneficiaries/beneficiaries";
import ClientWallet from "../Wallet/wallet";
import SpotProfitMargin from "../spotProfitMargin/spotProfitMargin";

export default function Clients() {
  const [activeTab, setActiveTab] = useState("Clients");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "Clients":
        return renderClientDetails();
      case "Deals":
        return <ClientDeals />;
      case "Beneficiaries":
        return <ClientBeneficiaries />;
      case "Wallet":
        return <ClientWallet />;
      case "Spot Profit Margin" :
        return <SpotProfitMargin/> ;
      default:
        return null;
    }
  };

  const renderClientDetails = () => {
    const clientDetails = [
      { label: "Brand", value: "Equals" },
      { label: "Name", value: "Iconic State LTD" },
      { label: "Company Reg NO", value: "07594605" },
      { label: "Address", value: "27 Clonner Feils,Stophy Nutrition Sandwich" },
      { label: "Post Code", value: "CW5 7GU" },
      { label: "Country", value: "United Kingdom" },
      { label: "Telephone No", value: "+44 1270483763" },
      { label: "Website", value: "www.gives.co.uk" },
      { label: "Business Type", value: "Limited Company" },
      // Add more data as needed
    ];

    const industryDetails = [
      { label: "Sub-Industry", value: "OLD-INDUSTRY industrial Goods/Manufacturing Construction" },
      { label: "Account Manager", value: "Jacob Arbury" },
      { label: "Business Developer", value: "Jacob Arbury" },
      // Add more data as needed
    ];

    const fxRequirementDetails = [
      { label: "Purpose of Payments", value: "" },
      { label: "Source of Funds", value: "Send of Foreign Currency" },
      { label: "Currencies Dealing With", value: "EUR" },
      // Add more data as needed
    ];

    return (
      <div id="Clients" style={{ display: "block" }}>
        <div className="card mt-2">
          <div className="row" >
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="account-details my-4 px-4">
                <h3 className="mb-3">Account Details</h3>
                <div className="account-details-table ps-2 card-datatable table-responsive">
                  <table style={{ width: "100%" }}>
                    {clientDetails.map((detail, index) => (
                      <tr key={index}>
                        <td style={{ width: "280.172px" }}><b>{detail.label}</b></td>
                        <td><small className="fw-medium">{detail.value}</small></td>
                      </tr>
                    ))}
                    <h3 className="my-3">Industry Details</h3>
                    {industryDetails.map((detail, index) => (
                      <tr key={index}>
                        <td style={{ width: "280.172px" }}><b>{detail.label}</b></td>
                        <td><small className="fw-medium">{detail.value}</small></td>
                      </tr>
                    ))}
                    <h3 className="my-3">Fx Requirement Details</h3>
                    {fxRequirementDetails.map((detail, index) => (
                      <tr key={index}>
                        <td style={{ width: "280.172px" }}><b>{detail.label}</b></td>
                        <td><small className="fw-medium">{detail.value}</small></td>
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="customer-notes mt-4 ps-3">
                <div className="d-flex justify-content-between mb-2">
                  <h3 className="mb-3" >Customer Notes</h3>
                  <button class="btn btn-primary py-2 px-3">Add Notes</button>
                </div>
                <div className="table-responsive card-datatable">
                  <table className="datatables-customers table border-top dataTable no-footer dtr-column" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info">
                    <thead className="table-head-bg p-3 hm-table-color">
                      <tr>
                        <th className="sorting sorting_desc" rowSpan={1} colSpan={1} style={{ width: "490px" }} >Name</th>
                        <th className="sorting" rowSpan={1} colSpan={1} style={{ width: "280.172px" }} aria-label="Country: activate to sort column ascending" >Added By</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td ><b>AC162514</b></td>
                        <td><b>Sarah Cliffe on 14-03-2023 <small>11:39</small></b></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* Trade Limits */}
              <div className="trade-limits mt-5 ps-3">
                <h3 className="mb-3" >Trade Limits</h3>
                <div className="table-responsive card-datatable">
                  <table className="datatables-customers table border-top dataTable no-footer dtr-column" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info">
                    <thead className="table-head-bg  p-3 hm-table-color">
                      <tr>
                        <th className="sorting sorting_desc" rowSpan={1} colSpan={1} style={{ width: "204.359px" }} >
                          Limits
                        </th>

                        <th className="sorting" rowSpan={1} colSpan={1} style={{ width: "115.172px" }} aria-label="Country: activate to sort column ascending" >
                          Currency
                        </th>

                        <th className="sorting" rowSpan={1} colSpan={1} style={{ width: "142.703px" }}>
                          Current
                        </th>

                        <th className="sorting" rowSpan={1} colSpan={1} style={{ width: "142.703px" }}>
                          Remaining
                        </th>

                      </tr>

                    </thead>

                    <tbody>

                      <tr>
                        <td><small>Single Trade Limit </small></td>
                        <td><small>GBP</small></td>
                        <td><small>250,000.00</small></td>
                        <td><small>N/A</small></td>
                      </tr>

                      <tr>
                        <td><small>Monthly Trade Limit</small></td>
                        <td><small>GBP</small></td>
                        <td><small>250,000.00</small></td>
                        <td><small>240,324.05</small> </td>
                      </tr>

                      <tr>
                        <td><small>12 Month Trade Limit</small></td>
                        <td><small>GBP</small></td>
                        <td><small>2,000,000.00</small></td>
                        <td><small>1,191,440.83</small></td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
              {/* access */}
              <div className="access my-3 ps-3">
                <h3 className="mb-3" >Access</h3>
                <div className="access ps-4">
                  <table style={{ width: "100%" }}>

                    <tr>
                      <td style={{ width: "280.172px" }}><b>Client trading status</b></td>
                      <td><img src={Check} alt="" /> <small class="fw-medium">Active</small> </td>
                    </tr>

                    <tr>
                      <td style={{ width: "280.172px" }}><b>Spot Booking</b></td>
                      <td><i class="ti ti-browser-check ti-sm text-success"></i> <small class="fw-medium">Authorized</small> </td>
                    </tr>

                    <tr>
                      <td style={{ width: "280.172px" }}><b>Wallet</b></td>
                      <td><i class="ti ti-browser-check ti-sm text-success"></i> <small class="fw-medium">Authorized</small> </td>
                    </tr>

                    <tr>
                      <td style={{ width: "280.172px" }}><b>Forward Booking</b></td>
                      <td><i class="ti ti-ban ti-sm text-danger"></i> <small class="fw-medium">Not Authorized</small> </td>
                    </tr>

                  </table>
                </div>
              </div>
            </div>
            <Table />
          </div>
        </div>
      </div>
    );
  };


  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Aside />
          <div className="layout-page">
            <Navbar />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="pt-3 mb-2">
                  Iconic Slate LTD <span className="text-muted fw-light">(GBR809995)</span>
                </h4>
                <h5 className="customer-head fw-light mb-2">
                  This Account is managed by <span>"Jacob Arbury"</span>
                </h5>
                {/* Buttons */}
                <div className="card">
                  <div className="card-header">
                    <ul className="nav nav-pills card-header-pills" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          type="button"
                          onClick={() => handleTabChange("Clients")}
                          className={`nav-link ${activeTab === "Clients" ? "active" : ""}`}
                        >
                          Client Details
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          type="button"
                          onClick={() => handleTabChange("Deals")}
                          className={`nav-link ${activeTab === "Deals" ? "active" : ""}`}
                        >
                          Deals
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          type="button"
                          onClick={() => handleTabChange("Beneficiaries")}
                          className={`nav-link ${activeTab === "Beneficiaries" ? "active" : ""}`}
                        >
                          Beneficiaries
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          type="button"
                          onClick={() => handleTabChange("Wallet")}
                          className={`nav-link ${activeTab === "Wallet" ? "active" : ""}`}
                        >
                          Wallet
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          type="button"
                          onClick={() => handleTabChange("Spot Profit Margin")}
                          className={`nav-link ${activeTab === "Spot Profit Margin" ? "active" : ""}`}
                        >
                          Spot Profit Margin
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          type="button"
                          onClick={() => handleTabChange("Customer Fees")}
                          className={`nav-link ${activeTab === "Customer Fees" ? "active" : ""}`}
                        >
                          Customer Fees
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                {renderTabContent()}
                <Footer />
                <div className="content-backdrop fade"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
